class Auth {
  constructor(engineerRepository, api) {
    this.api = api;
    this.engineerRepository = engineerRepository;
    this.setToken(this.engineerRepository.getToken());
  }

  signedIn() {
    return this.token !== null;
  }

  getToken() {
    return this.token;
  }

  signOut() {
    this.setToken(null);
  }

  setToken(token) {
    this.token = token;
    this.engineerRepository.setToken(token);
    this.api.setToken(token);
  }

  authenticate(email, password) {
    const auth = this;
    return new Promise(function(resolve, reject) {
      auth.api.login(email, password).then(function(token) {
        auth.setToken(token);
        resolve(token);
      }).catch(function(data) {
        if (data && data.error) {
          reject(data.error);
        } else {
          reject('Unknown error');
        }
      });
    });
  }
}

export default Auth;
