import React from 'react';

import './ResultsHeading.css';

const ResultsHeading = props => (
  <h1 className="ResultsHeading">
    {props.children}
  </h1>
);

export default ResultsHeading;
