import React, { Component } from 'react';

import { TranslationContext } from '../translations/TranslationContext';

import alignLeft from './align-left.svg';

import './ViewButton.css';

class ViewDatasheetButton extends Component {
  constructor(props) {
    super(props);
    this.openUrl = this.openUrl.bind(this);
  }

  t = (id) => this.context.translate('components.view_datasheet_button.' + id);

  openUrl() {
    window.open(this.props.url);
  }

  render() {
    if (!this.props.url) {
      return null;
    }
    let className = 'ViewButton DefaultButton';
    let text = this.t('view');
    if (this.props.wide) {
      className += ' WideButton';
      text = this.t('view_datasheet');
    }
    return(
      <button className={className} onClick={this.openUrl}>
        <span className="ViewButton-text">{text}&nbsp;</span><span className="ViewButton-img"><img src={alignLeft} alt=""/></span>
      </button>
    );
  }
}

ViewDatasheetButton.contextType = TranslationContext;

export default ViewDatasheetButton;
