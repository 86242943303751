// A repository for element images backed by the database and local filesystem.
class ElementImageRepositoryLocal {
  constructor(db, userDataPath) {
    this.db = db;
    this.userDataPath = userDataPath;
    this.elementImages = null;
  }

  fetchElementImages(parkerNumber) {
    return this.fetchAllImages().then(images => {
      console.log('All element images', images);
      const ourImages = images.filter(i => i.parkerNumber === parkerNumber);
      if (ourImages.length === 0) {
        console.warn('No element images found for parkerNumber', parkerNumber);
      }
      return ourImages;
    });
  }

  fetchAllImages() {
    if (this.elementImages) {
      return Promise.resolve(this.elementImages);
    } else {
      return this.db.then(db => {
        const range = IDBKeyRange.bound('elements/', 'elements/\uffff', false, false);
        return db.transaction(['resources'], 'readonly')
          .objectStore('resources')
          .index('path')
          .getAll(range);
      }).then(resources => {
        this.elementImages = resources.map(r => ({
          parkerNumber: this.extractParkerNumber(r.path),
          url: 'file://' + encodeURI(this.userDataPath) + '/' + r.path
        }));
        return this.elementImages;
      });
    }
  }

  extractParkerNumber(path) {
    return path.split('_')[0].replace('elements/', '').split('.')[0];
  }
}

export default ElementImageRepositoryLocal;
