class ContactMessageRequest {
  /**
   * Constructs a request object with path and data properties that can be
   * passed to `API.post()`.
   */
  constructor(subject, message) {
    this.path = 'contact_messages';
    this.data = {
      subject: subject,
      message: message
    };
  }
}

export default ContactMessageRequest;
