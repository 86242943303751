import React, { Component } from 'react';
import './LabeledTextArea.css';

class LabeledTextArea extends Component {
  render() {
    return (
      <div className="LabeledInputField">
        <label className="LabeledInputField-label" htmlFor={this.props.id}>{this.props.label}</label>
        <textarea
          id={this.props.id}
          onChange={(event) => this.props.onChange(this.props.id, event.target.value)}
          value={this.props.value}/>
      </div>
    );
  }
}

export default LabeledTextArea;
