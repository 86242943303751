import en_GB from './en_GB.json';
import fr_FR from './fr_FR.json';

export default class TranslationsLocalStorage {
  constructor(localStorage, api) {
    this.localStorage = localStorage;
    this.api = api;
  }

  locales = {
    en_GB, fr_FR
  };

  localeKey = (locale) => 'locale_' + locale;

  // Try network first.
  // If we get a translation from network, cache it in Local Storage and
  // return it.
  // In case of no network, fetch from the cache or fall back to our
  // bundled translations.
  fetchTranslation = (locale) =>
    this.api.fetchTranslation(locale)
      .then(translation => {
        this.localStorage.setItem(
          this.localeKey(locale), JSON.stringify(translation)
        );
        return Promise.resolve(translation);
      })
      .catch(error => {
        const translation = JSON.parse(
          this.localStorage.getItem(this.localeKey(locale))
        );
        return Promise.resolve(translation || this.locales[locale]);
      });
}
