import React from 'react';

import './RightPanel.css';
import './WidePanel.css';

const WidePanel = (props) => {
  return (
    <div className="RightPanel WidePanel">{props.children}</div>
  );
}

export default WidePanel;
