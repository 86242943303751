import React, { Component } from 'react';

import ConditionalButton from '../components/ConditionalButton';
import InputField from '../components/InputField';

import cleanQuery from '../CleanQuery';

import { TranslationContext } from '../translations/TranslationContext';

class OEMPartNumber extends Component {
  constructor() {
    super();

    this.state = {
      partNumber: ''
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  t = (id) => this.context.translate('oem_apps.oem_part_number.' + id);

  handleFieldChange(_, value) {
    this.setState({ partNumber: value.toUpperCase() });
  }

  canSearch() {
    return this.state.partNumber && cleanQuery(this.state.partNumber).length >= 3;
  }

  render() {
    return (
      <div className="OEMPartNumber">
        <form onSubmit={(e) => { e.preventDefault(); this.props.onSearch(this.state.partNumber); }}>
          <label>
            <h2>{this.props.title}</h2>
            <InputField
              id="partNumber"
              value={this.state.partNumber}
              type="text"
              onChange={this.handleFieldChange}
            />
          </label>
          <ConditionalButton
            type="submit"
            text={this.t('search')}
            condition={() => this.canSearch()}
          />
        </form>
      </div>
    );
  }
}

OEMPartNumber.contextType = TranslationContext;

export default OEMPartNumber;
