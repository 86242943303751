import { tr, detr } from './Muddy';

// A repository for products backed by an IndexedDB database.
class ProductRepositoryDB {
  constructor(db) {
    this.db = db;
  }

  modelsForOEM(oemId) {
    if (!oemId) {
      return new Promise(function(resolve, reject) {
        resolve([]);
      });
    }

    return this.db.then(db =>
      db.transaction(['products'], 'readonly')
        .objectStore('products')
        .index('oem_id')
        .getAll(oemId)
    ).then(products => [...new Set(products.map(p => p.model))].sort());
  }

  forOEMAndModel(oemId, model) {
    // Fetch products.
    return this.db.then(db =>
      db.transaction(['products'], 'readonly')
        .objectStore('products')
        .index('oem_id')
        .getAll(oemId)
    ).then(
      products => this.deob(products.filter(p => p.model === model))
    );
  }

  deob(products) {
    let arr = [];
    console.log(products);
    for (let product of products) {
      if (!product) {
        continue;
      }
      let newProduct = Object.assign({}, product);
      newProduct.oem_number = detr(newProduct.oem_number);
      newProduct.parker_number = detr(newProduct.parker_number);
      arr.push(newProduct);
    }
    return arr;
  }

  findOEM(oemId) {
    return this.db.then(db =>
      db.transaction(['oems'], 'readonly')
        .objectStore('oems')
        .get(oemId)
    );
  }

  forOEMPartNumber(partNumber) {
    return this.forPartNumber('oem', partNumber);
  }

  forParkerPartNumber(partNumber) {
    return this.forPartNumber('parker', partNumber);
  }

  forPartNumber(whose, partNumber) {
    partNumber = tr(partNumber);
    let range = this.partNumberRange(partNumber);
    return this.db.then(db =>
      db.transaction(['products'], 'readonly')
        .objectStore('products')
        .index(whose + '_number_n')
        .getAll(range)
    ).then(results => this.deob(results));
  }

  // Returns an IDBKeyRange for searching for part numbers beginning with the
  // given prefix.
  partNumberRange(prefix) {
    let number = prefix.toUpperCase();
    return IDBKeyRange.bound(number, number + '\uffff', false, false);
  }
}

export default ProductRepositoryDB;
