class EnquiryRequest {
  /**
   * Constructs a request object with path and data properties that can be
   * passed to `API.post()`.
   */
  constructor(enquiries) {
    this.path = 'enquiries';
    this.data = {
      product_enquiries: enquiries.map(function (e) {
        return {
          parker_number: e.parkerNumber,
          quantity: e.quantity,
          demand_type: e.demandType
        };
      })
    };
  }
}

export default EnquiryRequest;
