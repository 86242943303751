import Updater from './Updater';

export default class ResourceUpdater extends Updater {
  dependent = false;

  constructor(api, db, repos, downloader, name, collection) {
    super(api, db, repos);
    this.downloader = downloader;
    this.name = name;
    this.collection = collection;

    // TODO: Status not properly supported until we align updateAvailable checks.
    this.setState({ status: {} });
  }

  statusCollection = () => 'resources/' + this.collection;

  // Overrides to prevent name clash between styles (the database table) and
  // styles (the image resource).
  updatedAtKey = () => 'resources_' + this.collection + 'UpdatedAt';
  countKey = () => 'resources_' + this.collection + 'Count';

  update() {
    return this.downloader.downloadCollection(
      this.collection, this.onResourceProgress.bind(this)
    );
  }

  onResourceProgress(info) {
    this.setState({ percentageComplete: info.percent });
  }
}
