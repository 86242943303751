import React, { Component } from 'react';

import './ElementImages.css';
import imagePrevious from './image-previous.svg';
import imageNext from './image-next.svg';

export default class ElementImages extends Component {
  constructor() {
    super();
    this.state = {
      currentImage: 0
    };
  }

  render() {
    if (this.props.images.length === 0) {
      return null;
    } else {
      return (
        <div className="ElementImages">
          <button className="ElementImages-button ElementImages-button-previous" onClick={() => this.previousImage()}><img src={imagePrevious} alt="Previous"/></button>
          <button className="ElementImages-button ElementImages-button-next" onClick={() => this.nextImage()}><img src={imageNext} alt="Next"/></button>
          <div className="ElementImages-image">
            <img className="ElementImages-image"
              src={this.props.images[this.state.currentImage].url}
              alt="Element"/>
          </div>
        </div>
      );
    }
  }

  nextImage() {
    let currentImage = this.state.currentImage + 1;
    if (currentImage === this.props.images.length) {
      currentImage = 0;
    }
    this.setState({ currentImage });
  }

  previousImage() {
    let currentImage = this.state.currentImage - 1;
    if (currentImage === -1) {
      currentImage = this.props.images.length - 1;
    }
    this.setState({ currentImage });
  }
}
