import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ViewButton.css';
import infoCircle from './info-circle.svg';

import { TranslationContext } from '../translations/TranslationContext';

class ViewMediaInfoButton extends Component {

  t = (id) => this.context.translate('components.view_media_info_button.' + id);

  render() {
    return(
      <button className="ViewButton DefaultButton"
        onClick={() => this.props.onClick(this.props.parkerNumber)}>
        {this.t('view')}&nbsp;<img src={infoCircle} alt=""/>
      </button>
    );
  }
}

ViewMediaInfoButton.propTypes = {
  parkerNumber: PropTypes.string.isRequired
};

ViewMediaInfoButton.contextType = TranslationContext;

export default ViewMediaInfoButton;
