// A repository for type images backed by the database and local filesystem.
class TypeImageRepositoryLocal {
  constructor(db, userDataPath) {
    this.db = db;
    this.userDataPath = userDataPath;
    this.typeImages = null;
  }

  fetchTypeImages(id) {
    return this.fetchAllImages().then(images => {
      const ourImages = images.filter(i => i.id === parseInt(id));
      if (ourImages.length === 0) {
        console.warn('No type images found for id', id);
        return {};
      }
      return ourImages;
    });
  }

  fetchAllImages() {
    if (this.typeImages) {
      return Promise.resolve(this.typeImages);
    } else {
      return this.db.then(db => {
        const range = IDBKeyRange.bound('types/', 'types/\uffff', false, false);
        return db.transaction(['resources'], 'readonly')
          .objectStore('resources')
          .index('path')
          .getAll(range);
      }).then(resources => {
        this.typeImages = resources.map(r => ({
          id: this.extractId(r.path),
          url: 'file://' + encodeURI(this.userDataPath) + '/' + r.path
        }));
        return this.typeImages;
      });
    }
  }

  extractId(path) {
    return parseInt(path.split('_')[0].replace('types/', '').split('.')[0]);
  }
}

export default TypeImageRepositoryLocal;
