import React, { Component } from 'react';
import './InputField.css';

class InputField extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    var value = event.target.value;
    this.props.onChange(this.props.id, value);
  }

  render() {
    return (
      <input
        id={this.props.id} type={this.props.type} className="InputField"
        onChange={this.handleChange} value={this.props.value}
      />
    );
  }
}

export default InputField;
