import React, { Component } from 'react';

import AddEnquiryButton from '../components/AddEnquiryButton';
import BackButton from '../components/BackButton';
import ElementImages from './ElementImages';
import EmfeProductInfo from './EmfeProductInfo';
import Error from '../components/Error';
import HifeProductInfo from './HifeProductInfo';
import PrimaryPanel from '../components/panels/PrimaryPanel';
import SecondaryPanel from '../components/panels/SecondaryPanel';
import ViewDatasheetButton from '../components/ViewDatasheetButton';

export default class ProductInfo extends Component {
  constructor() {
    super();
    this.state = {
      element: null,
      elementImages: [],
      notFound: false
    };
  }

  componentDidMount() {
    const parkerNumber = this.props.parkerNumber || this.props.match.params.parkerNumber;
    this.props.elementExpert
      .fetchElement(parkerNumber)
      .then(element => {
        this.setState({element, notFound: element === null});
        return this.addDatasheetUrl();
      });
    this.props.elementImages.fetchElementImages(parkerNumber)
      .then(elementImages => {
        console.log(elementImages);
        this.setState({elementImages})
      });
  }

  addDatasheetUrl() {
    let elements = [this.state.element];
    return this.props.datasheets.addUrls(elements)
      .then(matchedData =>
        this.setState({
          element: matchedData[0]
        })
      ).catch(error => console.error(error));
  }

  render() {
    return (
      <div className="ProductInfo">
        <PrimaryPanel>
          <BackButton onClick={() => this.props.onBack()}/>
          <h1>Product information</h1>
          {this.notFound()}
          {this.productInfo()}
          {this.buttons()}
        </PrimaryPanel>
        <SecondaryPanel>
          <ElementImages images={this.state.elementImages}/>
        </SecondaryPanel>
      </div>
    );
  }

  notFound() {
    if (this.state.notFound) {
      return (
        <Error>
          There is currently no information for this product.
        </Error>
      );
    } else {
      return null;
    }
  }

  productInfo() {
    if (this.state.element === null) {
      return null;
    } else {
      if (this.state.element.division_name === 'Hydraulic') {
        return <HifeProductInfo element={this.state.element}/>;
      } else if (this.state.element.division_name === 'Engine Filtration') {
        return <EmfeProductInfo element={this.state.element}/>;
      }
    }
  }

  buttons() {
    if (this.state.element === null) {
      return null;
    }
    return (
      <div className="ProductInfo-buttons">
        <AddEnquiryButton
          enquiries={this.props.enquiries}
          parkerNumber={this.state.element.parker_number}
          wide={true}
        />
        <ViewDatasheetButton
          url={this.state.element.datasheetUrl}
          wide={true}
        />
      </div>
    );
  }
}
