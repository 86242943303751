import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import DefaultButton from './DefaultButton';
import FormGroup from './FormGroup';

import './CannotFindLinkBlock.css';

import { TranslationContext } from '../translations/TranslationContext';



class CannotFindLinkBlock extends Component {

  t = (id) => this.context.translate('components.cannot_find_link_block.' + id);

  render() {
    return (
      <div className="CannotFindLinkBlock">
        <FormGroup>
          <h3>{this.t('cant_find')}</h3>
          <Route render={({ history }) => (
            <DefaultButton
              type='button'
              onClick={() => { history.push('/cannot-find') }}
              text={this.t('enquiry')} />
          )} />
        </FormGroup>
      </div>
    )
  }
}

CannotFindLinkBlock.contextType = TranslationContext;

export default CannotFindLinkBlock;
