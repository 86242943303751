import Updater from './Updater';

export default class ElementUpdater extends Updater {
  dependent = true;
  name = 'Elements';
  collection = 'elements';
  store = 'elements';

  update() {
    // Download and populate elements object store.
    return this.api.fetchElements()
      .then(data =>
        this.clearStore().then(_ => this.updateWith(data))
      );
  }

  updateWith(data) {
    return this.db.then(db => {
      let done = 0;
      let count = data.elements.length || 1;
      let lastPercent = 0;
      let funcs = [];

      // Break our huge element data set into chunks so we can report on
      // progress. If we do it all in one go then there will be a large
      // pause without feedback where the transaction is building up. Instead
      // we run a number of smaller transactions in sequence.
      this.eachSlice(data.elements, 750, elements => {
        // Add each transaction task to funcs array.
        funcs.push(_ => {
          const tx = db.transaction(['elements'], 'readwrite');
          const store = tx.objectStore('elements');

          elements.forEach(object => {
            // The id key is from the product_statuses table rather than the
            // elements table and may sometimes be null in which case we
            // don't want it and can't store it, hence the finally.
            store.put(object).catch(error => {
              console.warn(error);
            }).finally(_ => {
              done ++;
              let percent = Math.round(100 * done / count);
              if (percent !== lastPercent) {
                lastPercent = percent;
                this.setState({ percentageComplete: percent });
              }
            })
          });
          return tx.complete;
        });
      });

      // Now run the transaction tasks.
      return this.promiseSerial(funcs);
    });
  }
}
