export default class StyleImageRepositoryLocal {
  constructor(db, userDataPath) {
    this.db = db;
    this.userDataPath = userDataPath;
  }

  fetchStyleImages(id) {
    return this.fetchAllImages().then(images => {
      const ourImages = images.filter(i => i.id === parseInt(id));
      console.log('ourImages', ourImages);
      if (ourImages.length === 0) {
        console.warn('No style images found for id', id);
        return {};
      }
      return {
        imageUrl: ourImages[0].url,
        lineDrawingUrl: ourImages[1].url,
      };
    });
  }

  fetchAllImages() {
    if (this.styleImages) {
      return Promise.resolve(this.styleImages);
    } else {
      return this.db.then(db => {
        const range = IDBKeyRange.bound('styles/', 'styles/\uffff', false, false);
        return db.transaction(['resources'], 'readonly')
          .objectStore('resources')
          .index('path')
          .getAll(range);
      }).then(resources => {
        this.styleImages = resources.map(r => ({
          id: this.extractId(r.path),
          url: 'file://' + encodeURI(this.userDataPath) + '/' + r.path
        }));
        return this.styleImages;
      });
    }
  }

  extractId(path) {
    return parseInt(path.split('_')[0].replace('styles/', '').split('.')[0]);
  }
}
