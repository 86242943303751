import React, { Component } from 'react';

import AddEnquiryButton from '../components/AddEnquiryButton';
import BackButton from '../components/BackButton';
import CannotFindLinkBlock from '../components/CannotFindLinkBlock';
import FullPanel from '../components/FullPanel';
import LoadMore from '../components/LoadMore';
import NoResults from '../components/NoResults';
import ProductInfo from '../product_info/ProductInfo';
import ResultsHeading from '../components/ResultsHeading';
import TickCross from '../components/TickCross';
import ViewDatasheetButton from '../components/ViewDatasheetButton';
import ViewInfoButton from '../components/ViewInfoButton';

import { TranslationContext } from '../translations/TranslationContext';

class OEMResults extends Component {
  constructor() {
    super();

    this.state = {
      productInfo: null
    };
  }

  t = (id) => this.context.translate('oem_apps.oem_results.' + id);

  render() {
    if (this.state.productInfo) {
      return this.productInfo();
    } else {
      return this.oemResults();
    }
  }

  productInfo() {
    return (
      <ProductInfo
        onBack={() => this.setState({ productInfo: null })}
        parkerNumber={this.state.productInfo}
        datasheets={this.props.datasheets}
        elementExpert={this.props.elementExpert}
        elementImages={this.props.elementImages}
        enquiries={this.props.enquiries}
        />
    );
  }

  oemResults() {
    var section = null;
    if (this.props.results.data.length) {
      section = this.resultsTable();
    } else {
      section = <NoResults/>;
    }

    return (
      <FullPanel>
        <BackButton onClick={this.props.onBack} />
        <ResultsHeading>{this.t('oem_apps_results')}</ResultsHeading>
        {section}
      </FullPanel>
    );
  }

  resultsTable() {
    let table = [];
    for (let i in this.props.results.data) {
      let product = this.props.results.data[i];
      if (table.length >= this.props.results.visible) {
        break;
      }
      table.push(
        <tr key={product.id}>
          <td>
            {product.oem.name}
          </td>
          <td>
            {product.model}
          </td>
          <td>
            {product.oem_number}
          </td>
          <td>
            {product.parker_number}
          </td>
          <td>
            <TickCross value={product.active} part={product}/>
          </td>
          <td>
            <TickCross value={product.stocked} part={product}/>
          </td>
          <td>
            {this.moreInfoButton(product)}
          </td>
          <td>
            <ViewDatasheetButton url={product.datasheetUrl}/>
          </td>
          <td>
            <AddEnquiryButton
              enquiries={this.props.enquiries}
              parkerNumber={product.parker_number}
            />
          </td>
        </tr>
      );
    }
    return (
      <div className="OEMResults">
        <div className="Results-container">
          <table className="Results Results-desktop">
            <thead>
              <tr>
                <th>{this.t('oem')}<span className="Results-sliver"></span></th>
                <th>{this.t('model')}<span className="Results-sliver"></span></th>
                <th>{this.t('oem_part_number')}<span className="Results-sliver"></span></th>
                <th>{this.t('parker_part_number')}<span className="Results-sliver"></span></th>
                <th>{this.t('active')}<span className="Results-sliver"></span></th>
                <th>{this.t('stocked')}<span className="Results-sliver"></span></th>
                <th>{this.t('more_info')}<span className="Results-sliver"></span></th>
                <th>{this.t('datasheet')}<span className="Results-sliver"></span></th>
                <th>{this.t('enquire')}<span className="Results-sliver"></span></th>
              </tr>
            </thead>
            <tbody>
              {table}
            </tbody>
          </table>
        </div>
        <LoadMore
          results={this.props.results}
          onLoadMore={() => this.props.onLoadMore()}
        />
        <CannotFindLinkBlock />
      </div>
    );
  }

  moreInfoButton(product) {
    if (product.element_id) {
      return (
        <ViewInfoButton
          onClick={(parkerNumber) => this.onProductInfo(parkerNumber)}
          parkerNumber={product.parker_number}
          />
      );
    }
    return null;
  }

  onProductInfo(parkerNumber) {
    this.setState({ productInfo: parkerNumber });
  }
}

OEMResults.contextType = TranslationContext;

export default OEMResults;
