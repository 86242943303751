import React, { useContext, useEffect, useState } from 'react';

import { Line } from 'rc-progress';

import { TranslationContext } from '../translations/TranslationContext';

function UpdateStatus(props) {
  const [status, setStatus] = useState(null);
  const [percentageComplete, setPercentageComplete] = useState(0);
  const context = useContext(TranslationContext);

  const t = (id) => context.translate('updates.update_status.' + id);
  const translateName = (name) => t(name.toLowerCase().replace(/ /g, '_'));

  function handleUpdaterChange(state) {
    setStatus(state.status);
    setPercentageComplete(state.percentageComplete);
  }

  useEffect(() => {
    props.updater.subscribe(handleUpdaterChange);
    return () => props.updater.unsubscribe(handleUpdaterChange);
  });

  return (
    <p>
      {translateName(props.updater.name)}: {percentageComplete}%
      <Line percent={percentageComplete} />
    </p>
  );
}

export default UpdateStatus;
