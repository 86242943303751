import React from 'react';
import './DisabledButton.css';

function DisabledButton(props) {
  return (
    <button className="DefaultButton DisabledButton" disabled>
      {props.text}
    </button>
  );
}

export default DisabledButton;
