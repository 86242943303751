import React, { Component } from 'react';

import AddEnquiryButton from '../components/AddEnquiryButton';
import BackButton from '../components/BackButton';
import CannotFindLinkBlock from '../components/CannotFindLinkBlock';
import FullPanel from '../components/FullPanel';
import Loader from '../components/Loader';
import LoadMore from '../components/LoadMore';
import NoResults from '../components/NoResults';
import ProductInfo from '../product_info/ProductInfo';
import ResultsHeading from '../components/ResultsHeading';
import TickCross from '../components/TickCross';
import ViewDatasheetButton from '../components/ViewDatasheetButton';
import ViewInfoButton from '../components/ViewInfoButton';

import './ElementSelector.css';

import { TranslationContext } from '../translations/TranslationContext';

class ElementSelectorResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productInfo: null,
      searching: true,
      results: {
        data: [],
        visible: 15
      }
    };

    const component = this;

    props.elementSelector.search(props.queries)
      .then(function(competitor_parts) {
        component.setState({
          results: {
            data: competitor_parts,
            visible: component.state.results.visible
          }
        });
        return component.props.datasheets.addUrls(competitor_parts);
      }).then(function(matchedData) {
        let results = component.state.results;
        results.data = matchedData;
        component.setState({ results, searching: false });
      }).catch(function(error) {
        console.error(error);
      });
  }

  t = (id) => this.context.translate('element_selector.element_selector_results.' + id);

  render() {
    if (this.state.productInfo) {
      return this.productInfo();
    } else {
      return this.elementSelectorResults();
    }
  }

  elementSelectorResults() {
    var section = null;
    if (this.state.searching) {
      section = <Loader/>;
    } else {
      if (this.state.results.data.length) {
        section = this.resultsTable();
      } else {
        section = <NoResults/>;
      }
    }

    return (
      <div className="ElementSelectorResults">
        <FullPanel>
          <BackButton onClick={this.props.onBack} />
          <ResultsHeading>{this.t('element_selector_results')}</ResultsHeading>
          {section}
        </FullPanel>
      </div>
    );
  }

  productInfo() {
    return (
      <ProductInfo
        onBack={() => this.setState({ productInfo: null })}
        parkerNumber={this.state.productInfo}
        datasheets={this.props.datasheets}
        elementExpert={this.props.elementExpert}
        elementImages={this.props.elementImages}
        enquiries={this.props.enquiries}
        />
    );
  }

  loadMore() {
    this.setState({
      results: {
        data: this.state.results.data,
        visible: this.state.results.visible + 15
      }
    });
  }

  resultsTable() {
    let table = [];
    for (let r in this.state.results.data) {
      let result = this.state.results.data[r];
      if (table.length >= this.state.results.visible) {
        break;
      }
      table.push(
        <tr key={r}>
          <td>
            {result.name}
          </td>
          <td>
            {result.competitor_number}
          </td>
          <td>
            {result.parker_number}
          </td>
          <td>
            <TickCross value={result.active} part={result}/>
          </td>
          <td>
            <TickCross value={result.stocked} part={result}/>
          </td>
          <td>
            {this.moreInfoButton(result)}
          </td>
          <td>
            <ViewDatasheetButton url={result.datasheetUrl}/>
          </td>
          <td>
            <AddEnquiryButton
              enquiries={this.props.enquiries}
              parkerNumber={result.parker_number}
            />
          </td>
        </tr>
      );
    }
    return (
      <div className="ElementSelectorResults">
        <div className="Results-container">
          <table className="Results Results-desktop">
            <thead>
              <tr>
                <th>{this.t('manufacturer')}<span className="Results-sliver"></span></th>
                <th>{this.t('manufacturer_part_number')}<span className="Results-sliver"></span></th>
                <th>{this.t('parker_part_number')}<span className="Results-sliver"></span></th>
                <th>{this.t('active')}<span className="Results-sliver"></span></th>
                <th>{this.t('stocked')}<span className="Results-sliver"></span></th>
                <th>{this.t('more_info')}<span className="Results-sliver"></span></th>
                <th>{this.t('datasheet')}<span className="Results-sliver"></span></th>
                <th>{this.t('enquire')}<span className="Results-sliver"></span></th>
              </tr>
            </thead>
            <tbody>
              {table}
            </tbody>
          </table>
        </div>
        <LoadMore
          results={this.state.results}
          onLoadMore={() => this.loadMore()}
        />
        <CannotFindLinkBlock />
      </div>
    );
  }

  moreInfoButton(result) {
    if (result.element_id) {
      return (
        <ViewInfoButton
          onClick={(parkerNumber) => this.onProductInfo(parkerNumber)}
          parkerNumber={result.parker_number}
          />
      );
    }
    return null;
  }

  onProductInfo(parkerNumber) {
    this.setState({ productInfo: parkerNumber });
  }
}

ElementSelectorResults.contextType = TranslationContext;

export default ElementSelectorResults;
