import uuidV4 from 'uuidv4';

export default class ResourceDownloader {
  constructor(ipc, api, db) {
    this.ipc = ipc;
    this.api = api;
    this.db = db;
  }

  downloadCollection(collection, onProgress) {
    const download = new CollectionDownload(this, onProgress, collection);
    return download.download();
  }

  fetchResources = (collection) => this.api.fetchResources(collection).then(data => data.resources);

  isStaleOrMissing(resource) {
    return this.db.then(db =>
      db.transaction(['resources'], 'readonly')
        .objectStore('resources')
        .get(resource.path)
    ).then(pathMatchedResource => {
      if (!pathMatchedResource) {
        return true;
      } else {
        return pathMatchedResource.modified !== resource.modified;
      }
    });
  }

  download(resource, retry = 0) {
    const encodedPath = resource.path.split('/').map(c => encodeURIComponent(c)).join('/');
    let url = this.api.siteBase + 'storage/uploads/' + encodedPath;
    let uuid = uuidV4();
    console.log('requesting download for uuid and url', uuid, url);
    this.ipc.send('download_resource', { uuid, url, path: resource.path });

    return new Promise((resolve, reject) => {
      this.ipc.once(`download_resource-success-${uuid}`,
        (event, localPath) => {
          console.log('download success', resource);

          this.db.then(db => {
            const tx = db.transaction(['resources'], 'readwrite');
            const store = tx.objectStore('resources');
            return store.put(resource);
          }).then(_ => resolve(localPath));
        }
      );
      this.ipc.once(`download_resource-error-${uuid}`,
        (event, args) => {
          if (args.error === null) {
            // Most likely two download setups occurring simultaneously.
            // Use a warning instead of an error as we expect these to happen
            // many times during the course of a large download session.
            // TODO: we whould probably use a different IPC message but the same
            // retry logic rather than test args.error which is a big magical.
            console.warn('download warning', args.message);
          } else {
            console.error('download error', args.message, args.error);
          }
          if (retry < 5) {
            retry ++;
            // Try again after a short pause to give the environment a chance
            // to change.
            setTimeout(() => {
                console.log('retrying #' + retry, resource);
                resolve(this.download(resource, retry));
              }, 10
            );
          } else {
            console.log('giving up on resource download', resource);
            resolve(args);
          };
        }
      );
    }).then(_ => console.log('done', uuid))
    .then(_ => new Promise(resolve => setTimeout(resolve, 20)));
  }
}

class CollectionDownload {
  constructor(downloader, onProgress, collection) {
    this.downloader = downloader;
    this.onProgress = onProgress;
    this.collection = collection;
    this.done = 0;
    this.count = 1;
    this.lastPercent = 0;
  }

  download() {
    return this.downloader.fetchResources(this.collection).then(resources => {
      if (resources.length > 0) {
        this.count = resources.length;
      } else {
        this.onProgress({ collection: this.collection, percent: 100 });
        return Promise.resolve('nothing to update');
      }
      return resources.reduce((p, resource) =>
        // Download resources sequentially.
        p.then(_ => this.downloadResource(resource)), Promise.resolve()
      );
    });
  }

  downloadResource(resource) {
    return this.downloader.isStaleOrMissing(resource).then(staleOrMissing => {
      if (staleOrMissing) {
        return this.downloader.download(resource);
      } else {
        return Promise.resolve();
      }
    }).then(() => {
      this.done ++;
      let percent = Math.round(100 * this.done / this.count);
      if (percent !== this.lastPercent) {
        this.lastPercent = percent;
        this.onProgress({ collection: this.collection, percent });
      }
      return Promise.resolve('cool beans');
    });
  }
}
