import React from 'react';
import './PrimaryPanel.css';

function PrimaryPanel(props) {
  return (
    <div className="PrimaryPanel">
      {props.children}
    </div>
  );
}

export default PrimaryPanel;
