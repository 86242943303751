import React, { Component } from 'react';

import RemoveButton from './RemoveButton';

import { asyncContainer, Typeahead } from 'react-bootstrap-typeahead';

import './RemovableInputField.css';

const AsyncTypeahead = asyncContainer(Typeahead);

class RemovableInputField extends Component {
  render() {
    return (
      <div className="RemovableInputField">
        <AsyncTypeahead
          id={this.props.id}
          isLoading={false}
          className="LabeledInputField-field RemovableInputField-field"
          onInputChange={(text, event) => this.props.onChange(this.props.id, text)}
          onChange={selected => this.props.onChange(this.props.id, selected[0] || '')}
          onSearch={this.props.onSearch}
          options={this.props.options}
          defaultSelected={[this.props.value]}
          filterBy={this.props.filterBy}
        />
        <RemoveButton onRemove={() => this.props.onRemove(this.props.id) }/>
      </div>
    );
  }
}

export default RemovableInputField;
