// A repository for OEMs backed by a the web API.
class OEMRepository {
  constructor(api) {
    this.api = api;
  }

  all() {
    return this.api.fetchOEMs().then(function(data) {
      return data.oems;
    });
  }

  find(id) {
    return this.api.fetchOEM(id).then(function(data) {
      return data.oem;
    });
  }
}

export default OEMRepository;
