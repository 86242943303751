import React from 'react';
import './Error.css';
import exclamation from './exclamation.svg';

const Error = (props) => {
  return (
    <div className="Error">
      <img className="Error-exclamation" src={exclamation} alt=""/>
      {props.children}
    </div>
  );
}

export default Error;
