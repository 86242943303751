import React, { Component } from 'react';

import { TranslationContext } from '../translations/TranslationContext';

import './ViewButton.css';
import basket from './basket.svg';

class AddEnquiryButton extends Component {
  t = (id) => this.context.translate('components.add_enquiry_button.' + id);

  render() {
    let className = 'ViewButton DefaultButton';
    if (this.props.wide) {
      className += ' WideButton';
    }

    if (this.props.enquiries.includes(this.props.parkerNumber)) {
      let text = this.t('added');
      if (this.props.wide) {
        text = this.t('added_to_my_enquiries');
      }
      return (
        <div className={className}>
          {text}
        </div>
      );
    } else {
      let text = this.t('add');
      if (this.props.wide) {
        text = this.t('add_to_my_enquiries');
      }
      return(
        <button className={className} onClick={() => this.props.enquiries.add(this.props.parkerNumber)}>
          <span className="ViewButton-text">{text}&nbsp;</span>
          <span className="ViewButton-img"><img src={basket} alt=""/></span>
        </button>
      );
    }
  }
}

AddEnquiryButton.contextType = TranslationContext;

export default AddEnquiryButton;
