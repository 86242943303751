import React from 'react';
import '../home/Home.css';
import logo from './parfit-toolkit.svg';

function BigHeader() {
  return (
    <div className="BigHeader">
      <img className="Home-parfit-toolkit" src={logo} alt="PAR&lt;&gt;FIT (TM) TOOLKIT"/>
    </div>
  );
}

export default BigHeader;
