import validQuantity from '../ValidQuantity';
import {demandTypes} from '../components/DemandTypes';

const storageKey = 'enquiries';

class EnquiriesModel {
  constructor(idGenerator, localStorage) {
    this.idGenerator = idGenerator;
    this.localStorage = localStorage;
    this.loadEnquiries();
  }

  // Returns the number of enquiries.
  count() {
    return this.enquiries.length;
  }

  // Returns the enquiry at the given index.
  get(index) {
    return this.enquiries[index];
  }

  // Returns all enquiries in an array.
  getAll() {
    return this.enquiries;
  }

  includes(parkerNumber) {
    let found = this.enquiries.find(e => e.parkerNumber === parkerNumber);
    return found !== undefined;
  }

  // Adds an enquiry for the given Parker number with random id, no default
  // quantity and default demand type.
  add(parkerNumber) {
    this.enquiries.push({
      id: this.idGenerator.generate(),
      parkerNumber: parkerNumber,
      quantity: '',
      demandType: demandTypes[0]
    });
    this.handleChange();
  }

  // Removes the enquiry identified by id.
  remove(id) {
    this.enquiries = this.enquiries.filter(e => e.id !== id);
    this.handleChange();
  }

  // Removes all enquiries.
  removeAll() {
    this.enquiries = [];
    this.handleChange();
  }

  // Updates the quantity of the enquiry identified by id.
  updateQuantity(id, quantity) {
    (this.find(id)).quantity = quantity;
    this.handleChange();
  }

  // Updates the demand type of the enquiry identified by id.
  updateDemandType(id, demandType) {
    (this.find(id)).demandType = demandType;
    this.handleChange();
  }

  // Finds the enquiry identified by id.
  find(id) {
    return this.enquiries.find(e => e.id === id);
  }

  valid() {
    for (let i = 0; i < this.enquiries.length; i++) {
      let enquiry = this.enquiries[i];
      if (!validQuantity(enquiry.quantity)) {
        return false;
      }
    }
    return true;
  }

  // Saves current enquiries and calls registered external onChange handler.
  handleChange() {
    this.saveEnquiries();
    this.onChange(this);
  }

  // Loads enquiries from localStorage, if present, or sets enquiries to an
  // empty array if not.
  loadEnquiries() {
    this.enquiries = JSON.parse(this.localStorage.getItem(storageKey) || '[]');
  }

  // Saves enquiries to localStorage.
  saveEnquiries() {
    this.localStorage.setItem(storageKey, JSON.stringify(this.enquiries));
  }
}

export default EnquiriesModel;
