// A repository for OEMS backed by an IndexedDB database.
class OEMRepositoryDB {
  constructor(db) {
    this.db = db;
  }

  all() {
    return this.db.then(db =>
      db.transaction(['oems'], 'readonly').objectStore('oems').getAll()
    ).then(oems =>
      Promise.resolve(oems.sort((a,b) => a.name.localeCompare(b.name)))
    );
  }

  find(id) {
    return this.api.fetchOEM(id).then(function(data) {
      return data.oem;
    });
  }
}

export default OEMRepositoryDB;
