import Updater from './Updater';

export default class CompetitorPartUpdater extends Updater {
  dependent = true;
  name = 'Manufacturer parts';
  collection = 'competitor_parts';
  store = 'competitorParts';

  update() {
    return this.repos.elementSelector.competitors()
      .then(competitors => this.updateWithCompetitors(competitors));
  }

  updateWithCompetitors(competitors) {
    // Download and populate competitor parts object store.
    return this.api.fetchCompetitorParts()
      .then(data =>
        this.clearStore().then(_ => this.updateWith(competitors, data))
      );
  }

  updateWith(competitors, data) {
    return this.db.then(db => {
      let done = 0;
      let count = data.competitor_parts.length || 1;
      let lastPercent = 0;
      let funcs = [];

      // Map competitor names to competitor ids for quick lookup.
      let competitorNames = [];
      for (let competitor of competitors) {
        competitorNames[competitor.id] = competitor.name;
      }

      // Break our huge product data set into chunks so we can report on
      // progress. If we do it all in one go then there will be a large
      // pause without feedback where the transaction is building up. Instead
      // we run a number of smaller transactions in sequence.
      this.eachSlice(data.competitor_parts, 750, competitorParts => {
        // Add each transaction task to funcs array.
        funcs.push(_ => {
          const tx = db.transaction(['competitorParts'], 'readwrite');
          const store = tx.objectStore('competitorParts');

          competitorParts.forEach(object => {
            // Add denormalized competitor data.
            object.name = competitorNames[object.competitor_id];

            store.put(object).then(_ => {
              done ++;
              let percent = Math.round(100 * done / count);
              if (percent !== lastPercent) {
                lastPercent = percent;
                this.setState({ percentageComplete: percent });
              }
            })
          });
          return tx.complete;
        });
      });

      // Now run the transaction tasks.
      return this.promiseSerial(funcs);
    });
  }
}
