import React, { Component } from 'react';

import Countries from './Countries';
import LabeledSelectField from './LabeledSelectField';

import { TranslationContext } from '../translations/TranslationContext';

class CountrySelectField extends Component {
  t = (id) => this.context.translate('components.country_select_field.' + id);

  render() {
    return (
      <LabeledSelectField
        label={this.t('country')}
        id="country"
        onChange={this.props.onChange}
        value={this.props.value}
      >
        {this.countryOptions()}
      </LabeledSelectField>
    );
  }

  countryOptions() {
    let options = [];
    for (var i = 0; i < Countries.length; i ++) {
      const country = Countries[i];
      options.push(<option key={country} value={country}>{country}</option>);
    }
    return options;
  }
}

CountrySelectField.contextType = TranslationContext;

export default CountrySelectField;
