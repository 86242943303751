import Updater from './Updater';

export default class StyleUpdater extends Updater {
  dependent = false;
  name = 'Styles';
  collection = 'styles';
  store = 'styles';

  update() {
    return this.api.fetchStyles()
      .then(data =>
        this.clearStore().then(_ => this.storeData(data))
      );
  }
}
