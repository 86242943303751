import React from 'react';

const DemandTypes = ({id, onChange, demandType}) => {
  return (
    <select
      id={id}
      className="LabeledSelectField-field"
      value={demandType}
      onChange={onChange}
    >
      {demandTypeOptions()}
    </select>
  );
}

export default DemandTypes;

export const demandTypes = ['One-Off', 'Annually'];

function demandTypeOptions() {
  let options = [];
  for (var i = 0; i < demandTypes.length; i++) {
    let type = demandTypes[i];
    options.push(
      <option key={type} value={type}>{type}</option>
    );
  }
  return options;
}
