import React from 'react';

import './RightPanel.css';
import './SecondaryPanel.css';

const SecondaryPanel = (props) => {
  return (
    <div className="RightPanel SecondaryPanel">{props.children}</div>
  );
}

export default SecondaryPanel;
