export default class Updater {
  constructor(api, db, repos) {
    this.api = api;
    this.db = db;
    this.repos = repos;
    this.subscribers = [];

    this.state = {
      percentageComplete: 0,
      status: null
    };
  }

  setState(newState) {
    console.log('setState', this.name);
    this.state = Object.assign({}, this.state, newState);
    for (let subscriber of this.subscribers) {
      subscriber(this.state);
    }
  }

  subscribe(subscriber) {
    this.subscribers.push(subscriber);
  }

  unsubscribe(subscriber) {
    this.subscribers.splice(subscriber);
  }

  bootstrap() {
    return this.updateAvailable().then(updateAvailable => {
      if (updateAvailable) {
        return this.update().then(_ => this.updated());
      }
      return Promise.resolve();
    });
  }

  updateAvailable() {
    const updatedAt = localStorage.getItem(this.updatedAtKey());
    const count = parseInt(localStorage.getItem(this.countKey()), 10);

    return this.api.fetchStatus(this.statusCollection()).then(data => {
      if (data.status.updated_at === updatedAt && data.status.count === count) {
        this.setState({ percentageComplete: 100, updateAvailable: false });
        return Promise.resolve(false);
      } else {
        this.setState({ status: data.status, updateAvailable: true });
        return Promise.resolve(true);
      }
    });
  }

  statusCollection = () => this.collection;

  updated() {
    localStorage.setItem(this.countKey(), this.state.status.count);
    localStorage.setItem(this.updatedAtKey(), this.state.status.updated_at);
    return Promise.resolve(true);
  }

  updatedAtKey = () => this.collection + 'UpdatedAt';
  countKey = () => this.collection + 'Count';

  // Delay function to prevent DOM lock-up during IndexedDB inserts.
  delay(t, v) {
    return new Promise(function(resolve) {
      setTimeout(resolve.bind(null, v), t);
    });
  }

  // Removes all items from IndexedDB object store.
  clearStore() {
    return this.db.then(db => {
      const tx = db.transaction([this.store], 'readwrite');
      const store = tx.objectStore(this.store);
      store.clear();
      return tx.complete;
    });
  }

  storeData(data) {
    return this.db.then(db => {
      const tx = db.transaction([this.store], 'readwrite');
      const store = tx.objectStore(this.store);
      let done = 0;
      let count = data[this.collection].length || 1;
      let lastPercent = 0;
      data[this.collection].forEach((object) => {
        store.put(object).then(_ =>
          this.delay(1)
        ).then(_ => {
          done ++;
          let percent = Math.round(100 * done / count);
          if (percent !== lastPercent) {
            lastPercent = percent;
            this.setState({ percentageComplete: percent });
          }
        })
      });
      return tx.complete;
    });
  }

  // Helper to run a number of asynchronous operations in sequence.
  // Since our sequence is a list of write transactions on the same object
  // store there is no benefit in running them in parallel.
  promiseSerial = funcs =>
    funcs.reduce((promise, func) =>
      promise.then(result => func().then(Array.prototype.concat.bind(result))),
      Promise.resolve([]))

  // Helper to split an array into sub-array slices.
  eachSlice = function(array, size, callback) {
    for (var i = 0, l = array.length; i < l; i += size) {
      callback.call(array, array.slice(i, i + size));
    }
  };
}
