import React from 'react';

import rightArrow from './right-arrow.svg';

import './Style.css';

const Style = (props) => {
  return (
    <button onClick={() => props.onStyleSelected(props.id)} className="Style">
      <div className="Style-name">
        {props.name}
      </div>
      <div className="Style-image">
        {props.styleImage}
      </div>
      <div className="Style-arrow">
        <img src={rightArrow} alt=""/>
      </div>
    </button>
  );
};

export default Style;
