import React, { Component } from 'react';

import './RadioButton.css';

import radio from './radio.svg';
import radioSelected from './radio-selected.svg';

export default class RadioButton extends Component {
  render() {
    if (this.props.selected) {
      return this.selected();
    } else {
      return this.unselected();
    }
  }

  selected() {
    return (
      <button className="RadioButton" type="button">
        <img src={radioSelected} alt={this.props.label + ' selected'}/>
        &nbsp;
        {this.props.label}
      </button>
    )
  }

  unselected() {
    return (
      <button className="RadioButton" onClick={() => this.props.onSelect()}>
        <img src={radio} alt={this.props.label + ' unselected'}/>
        &nbsp;
        {this.props.label}
      </button>
    )
  }
};
