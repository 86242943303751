import React, { Component } from 'react';
import BigHeader from '../components/BigHeader';
import EmptyPanel from '../components/panels/EmptyPanel';
import Intro from '../components/Intro';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import { TranslationContext } from '../translations/TranslationContext';

const background = require('./bg-register.jpg');

class CheckEmail extends Component {
  render() {
    return (
      <div className="CheckEmail">
        <PrimaryPanel>
          <BigHeader/>
          <Intro>
            {this.message()}
            {this.checkEmail()}
          </Intro>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  t = (id) => this.context.translate('account.check_email.' + id);

  message() {
    if (this.props.message === 'approved') {
      return (
        <p>{this.t('approved')}</p>
      );
    } else if (this.props.message === 'pending' || this.props.message === 'rejected') {
      return (
        <p>{this.t('pending')}</p>
      );
    }
  }

  checkEmail() {
    if (this.props.message === 'rejected') {
      return null;
    }
    return (
      <p>
        {this.t('check')}
      </p>
    );
  }
}

CheckEmail.contextType = TranslationContext;

export default CheckEmail;
