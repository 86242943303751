import React from 'react';

import './RightPanel.css';
import './EmptyPanel.css';

function EmptyPanel(props) {
  const style = {
    backgroundImage: props.background ? 'url(' + props.background + ')' : 'none',
    backgroundSize: 'cover'
  };
  return (
    <div className="RightPanel EmptyPanel" style={style}>
    </div>
  );
}

export default EmptyPanel;
