import React, { Component } from 'react';

import BackButton from '../components/BackButton';
import EmptyPanel from '../components/panels/EmptyPanel';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import './MediaInfo.css';
import equation1 from './equation_1.png';
import equation2 from './equation_1.png';

import background from '../element_selector/element-selector-bg.jpg';

export default class MediaInfo extends Component {
  render() {
    return (
      <div className="MediaInfo">
        <PrimaryPanel>
          <BackButton onClick={() => this.props.onBack()}/>
          <h1>Media information</h1>
          <div className="MediaInfo-inner">
            <div id="cleanliness-levels">
              <h3>Possible cleanliness levels to be reached by media type:</h3>
              <table className="Results">
                <thead>
                  <tr>
                    <th>Media Type<span className="Results-sliver"></span></th>
                    <th>Indicative Upper Range<span className="Results-sliver"></span></th>
                    <th>Indicative Lower Range<span className="Results-sliver"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2 Micron Absolute Fibreglass</td>
                    <td>15/13/10</td>
                    <td>13/11/8</td>
                  </tr>
                  <tr>
                    <td>5 Micron Absolute Fibreglass</td>
                    <td>17/15/12</td>
                    <td>16/14/9</td>
                  </tr>
                  <tr>
                    <td>10 Micron Absolute Fibreglass</td>
                    <td>20/18/15</td>
                    <td>18/16/10</td>
                  </tr>
                  <tr>
                    <td>20 Micron Absolute Fibreglass</td>
                    <td>22/20/17</td>
                    <td>21/19/13</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h3>Recommended ISO cleanliness codes per ISO 4406:1999:</h3>
              <table className="Results">
                <thead>
                  <tr>
                    <th>Component<span className="Results-sliver"></span></th>
                    <th>Pressure &lt;140 bar<span className="Results-sliver"></span></th>
                    <th>Pressure &lt;212 bar<span className="Results-sliver"></span></th>
                    <th>Pressure &gt;212 bar<span className="Results-sliver"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="4"><b>Pumps:</b></td>
                  </tr>
                  <tr>
                    <td>Fixed Gear</td>
                    <td>20/18/15</td>
                    <td>19/17/15</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Fixed Piston</td>
                    <td>19/17/14</td>
                    <td>18/16/13</td>
                    <td>17/15/12</td>
                  </tr>
                  <tr>
                    <td>Fixed Vane</td>
                    <td>20/18/14</td>
                    <td>19/17/15</td>
                    <td>18/16/13</td>
                  </tr>
                  <tr>
                    <td>Variable Piston</td>
                    <td>18/16/13</td>
                    <td>17/15/13</td>
                    <td>16/14/12</td>
                  </tr>
                  <tr>
                    <td>Variable Vane</td>
                    <td>18/16/13</td>
                    <td>17/15/12</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td colSpan="4"><b>Valves:</b></td>
                  </tr>
                  <tr>
                    <td>Cartridge</td>
                    <td>18/16/13</td>
                    <td>17/15/12</td>
                    <td>17/15/12</td>
                  </tr>
                  <tr>
                    <td>Check Valve</td>
                    <td>20/18/15</td>
                    <td>20/18/15</td>
                    <td>19/17/14</td>
                  </tr>
                  <tr>
                    <td>Directional (solenoid)</td>
                    <td>20/18/15</td>
                    <td>19/17/14</td>
                    <td>18/16/13</td>
                  </tr>
                  <tr>
                    <td>Flow Control</td>
                    <td>19/17/14</td>
                    <td>18/16/13</td>
                    <td>18/16/13</td>
                  </tr>
                  <tr>
                    <td>Pressure Control (modulating)</td>
                    <td>19/17/14</td>
                    <td>18/16/13</td>
                    <td>17/15/12</td>
                  </tr>
                  <tr>
                    <td>Proportional Cartridge Valve</td>
                    <td>17/15/12</td>
                    <td>17/15/12</td>
                    <td>16/14/11</td>
                  </tr>
                  <tr>
                    <td>Proportional Directional</td>
                    <td>17/15/12</td>
                    <td>17/15/12</td>
                    <td>16/14/11</td>
                  </tr>
                  <tr>
                    <td>Proportional Pressure Control</td>
                    <td>17/15/12</td>
                    <td>17/15/12</td>
                    <td>16/14/11</td>
                  </tr>
                  <tr>
                    <td>Proportional Flow Control</td>
                    <td>17/15/12</td>
                    <td>17/15/12</td>
                    <td>16/14/11</td>
                  </tr>
                  <tr>
                    <td>Servo Valve</td>
                    <td>16/14/11</td>
                    <td>16/14/11</td>
                    <td>15/13/10</td>
                  </tr>
                  <tr>
                    <td colSpan="4"><b>Bearings:</b></td>
                  </tr>
                  <tr>
                    <td>Ball Bearing</td>
                    <td>15/12/10</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Gearbox (industrial)</td>
                    <td>17/16/13</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Journal Bearing (high speed)</td>
                    <td>17/15/12</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Journal Bearing (low speed)</td>
                    <td>17/15/12</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Roller Bearing</td>
                    <td>16/14/11</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td colSpan="4"><b>Actuators:</b></td>
                  </tr>
                  <tr>
                    <td>Cylinder</td>
                    <td>17/15/12</td>
                    <td>16/14/11</td>
                    <td>15/13/10</td>
                  </tr>
                  <tr>
                    <td>Vane Motors</td>
                    <td>20/18/15</td>
                    <td>19/17/14</td>
                    <td>18/16/13</td>
                  </tr>
                  <tr>
                    <td>Axial Piston Motors</td>
                    <td>19/17/14</td>
                    <td>18/16/13</td>
                    <td>17/15/12</td>
                  </tr>
                  <tr>
                    <td>Gear Motors</td>
                    <td>20/18/14</td>
                    <td>19/17/13</td>
                    <td>18/16/13</td>
                  </tr>
                  <tr>
                    <td>Radial Piston Motors</td>
                    <td>20/18/15</td>
                    <td>19/17/14</td>
                    <td>18/16/13</td>
                  </tr>
                  <tr>
                    <td colSpan="4"><b>Test stands, Hydrostatic:</b></td>
                  </tr>
                  <tr>
                    <td>Test stands</td>
                    <td>15/13/10</td>
                    <td>15/13/10</td>
                    <td>15/13/10</td>
                  </tr>
                  <tr>
                    <td>Hydrostatic Transmissions</td>
                    <td>17/15/13</td>
                    <td>16/14/11</td>
                    <td>16/14/11</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="calculation">
              <h3>Calculation Filtration Quotient &beta;x(c):</h3>
              <img src={equation1} alt="" />
              <h3>Conversion Filtration Quotient &beta;x(c) into Filtration Efficency &#37;:</h3>
              <img src={equation2} alt="" />
            </div>
          </div>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }
}
