import React, { Component } from 'react';
import './AddFieldButton.css';
import './PlusMinus.css';

class AddFieldButton extends Component {
  render() {
    return(
      <div className="AddField">
        <button className="AddFieldButton" type="button" onClick={this.props.onAdd}>
          <div className="AddFieldButton-plus PlusMinus">+</div>
        </button>
      </div>
    );
  }
}

export default AddFieldButton;
