import React, { useContext, useState } from 'react';

import ConditionalButton from '../components/ConditionalButton';
import UpdateStatus from './UpdateStatus';

import { TranslationContext } from '../translations/TranslationContext';

function OptionalUpdate(props) {
  const [updateClicked, setUpdateClicked] = useState(false);
  const context = useContext(TranslationContext);

  const t = (id) => context.translate('updates.optional_update.' + id);

  return <>
    <UpdateStatus updater={props.updater} />
    <ConditionalButton
      onClick={() => { setUpdateClicked(true); props.updater.update().then(_ => props.updater.updated()) }}
      condition={() => !updateClicked} text={t('update')}
    />
    <br />
  </>;
}

export default OptionalUpdate;
