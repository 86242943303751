import React from 'react';
import DefaultButton from './DefaultButton';
import DisabledButton from './DisabledButton';
import LoadingButton from './LoadingButton';

function ConditionalButton(props) {
  if (props.loading) {
    return <LoadingButton text={props.text}/>;
  } else if (props.condition()) {
    return <DefaultButton text={props.text} onClick={props.onClick} type={props.type}/>;
  } else {
    return <DisabledButton text={props.text}/>;
  }
}

export default ConditionalButton;
