import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import BackButton from '../components/BackButton';
import CannotFindLinkBlock from '../components/CannotFindLinkBlock';
import EmptyPanel from '../components/panels/EmptyPanel';
import Intro from '../components/Intro';
import LabeledInputField from '../components/LabeledInputField';
import PrimaryPanel from '../components/panels/PrimaryPanel';
import ViewDatasheetButton from '../components/ViewDatasheetButton';

import { TranslationContext } from '../translations/TranslationContext';
import cleanQuery from '../CleanQuery';
import background from './datasheets-bg.jpg';

class Datasheets extends Component {
  constructor() {
    super();
    this.state = {
      datasheets: []
    };
  }

  t = (id) => this.context.translate('datasheets.datasheets.' + id);

  render() {
    return (
      <div className="Datasheets">
        <PrimaryPanel>
          <Route render={({history}) => (
            <BackButton onClick={() => { history.push('/') }}/>
          )} />
          <h1>{this.t('datasheets')}</h1>
          <Intro>
            {this.t('intro')}
          </Intro>
          <LabeledInputField
            id="part"
            label={this.t('type_to_find')}
            onChange={(_id, value) => this.findDatasheets(value)}
          />
          <br />
          {this.resultsTable()}
          <CannotFindLinkBlock />
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  findDatasheets(substring) {
    const component = this;
    return this.props.datasheets.matching(cleanQuery(substring)).then(function(datasheets) {
      component.setState({ datasheets: datasheets });
    });
  }

  resultsTable() {
    if (this.state.datasheets.length === 0) {
      return null;
    }

    let table = [];
    for (let r in this.state.datasheets) {
      let datasheet = this.state.datasheets[r];
      table.push(
        <tr key={r}>
          <td>
            {datasheet.partNumber}
          </td>
          <td>
            <ViewDatasheetButton url={datasheet.url}/>
          </td>
        </tr>
      );
    }

    return (
      <table className="Results">
        <thead>
          <tr>
            <th>{this.t('part_number')}<span className="Results-sliver"></span></th>
            <th>{this.t('datasheet')}<span className="Results-sliver"></span></th>
          </tr>
        </thead>
        <tbody>
          {table}
        </tbody>
      </table>
    );
  }
}

Datasheets.contextType = TranslationContext;

export default Datasheets;
