import React, { Component } from 'react';

import EmptyPanel from '../components/panels/EmptyPanel';
import Notification from './Notification';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import { TranslationContext } from '../translations/TranslationContext';

const background = require('./bg-notifications.jpg');

class Notifications extends Component {
  constructor() {
    super();
    this.handleRead = this.handleRead.bind(this);
    this.handleUnread = this.handleUnread.bind(this);
  }

  t = (id) => this.context.translate('notifications.notifications.' + id);

  render() {
    return(
      <div className="Notifications">
        <PrimaryPanel>
          <h1>{this.t('notifications')}</h1>
          {this.unreadNotifications()}
          {this.readNotifications()}
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  unreadNotifications() {
    if (this.props.notifications.unreadNotificationsCount() === 0) {
      return this.noUnreadNotifications();
    } else {
      return this.unreadNotificationsList();
    }
  }

  readNotifications() {
    return this.readNotificationsList();
  }

  noUnreadNotifications() {
    return (
      <p>{this.t('no_unread')}</p>
    );
  }

  unreadNotificationsList() {
    return this.notificationsList(this.props.notifications.unreadNotifications());
  }

  readNotificationsList() {
    return this.notificationsList(this.props.notifications.readNotifications());
  }

  notificationsList(notifications) {
    let elements = notifications.map(n => this.notification(n));
    return(
      <ul className="NotificationList">
        {elements}
      </ul>
    );
  }

  notification(n) {
    return (
      <Notification
        key={n.id}
        notification={n}
        onRead={this.handleRead}
        onUnread={this.handleUnread}
      />
    );
  }

  handleRead(notification) {
    console.log(this);
    this.props.notifications.markRead(notification.id);
  }

  handleUnread(notification) {
    this.props.notifications.markUnread(notification.id);
  }
}

Notifications.contextType = TranslationContext;

export default Notifications;
