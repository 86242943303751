import React from 'react';
import './Badge.css';

function Badge(props) {
  return (
    <div className="Badge">
      {props.count}
    </div>
  );
}

export default Badge;
