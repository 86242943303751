import React from 'react';

import './Intro.css';

function Intro(props) {
  return (
    <div className="Intro">
      {props.children}
    </div>
  );
}

export default Intro;
