import Updater from './Updater';

export default class CompetitorUpdater extends Updater {
  dependent = false;
  name = 'Manufacturers';
  collection = 'competitors';
  store = 'competitors';

  update() {
    return this.api.fetchCompetitors()
      .then(data =>
        this.clearStore().then(_ => this.storeData(data))
      );
  }
}
