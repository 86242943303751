import React, { Component } from 'react';

import ContactMessageRequest from './ContactMessageRequest';

import ConditionalButton from '../components/ConditionalButton';
import EmptyPanel from '../components/panels/EmptyPanel';
import EnquirySent from '../components/EnquirySent';
import FormGroup from '../components/FormGroup';
import Intro from '../components/Intro';
import LabeledInputField from '../components/LabeledInputField';
import LabeledTextArea from '../components/LabeledTextArea';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import { TranslationContext } from '../translations/TranslationContext';

const background = require('./contact-bg.jpg');

class Contact extends Component {
  constructor() {
    super();

    this.state = {
      sent: false,
      subject: '',
      message: ''
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  t = (id) => this.context.translate('contact.contact.' + id);

  handleFieldChange(fieldId, value) {
    var nextState = Object.assign({}, this.state);
    nextState[fieldId] = value;
    this.setState(nextState);
  }

  canSend() {
    return this.state.subject && this.state.message;
  }

  onSend(subject, message) {
    this.props.backgroundQueue.enqueue(
      new ContactMessageRequest(subject, message)
    );
    this.setState({ sent: true, subject: '', message: '' });
  }

  render() {
    return (
      <div className="Contact">
        <PrimaryPanel>
          <h2>{this.t('contact')}</h2>
          <Intro>
            <p>{this.t('intro')}</p>
          </Intro>
          <form onSubmit={(e) => { e.preventDefault(); this.onSend(this.state.subject, this.state.message); }}>
            <FormGroup>
              <LabeledInputField
                label={this.t('subject')}
                id="subject"
                onChange={this.handleFieldChange}
                value={this.state.subject}/>
              <LabeledTextArea
                label={this.t('message')}
                id="message"
                onChange={this.handleFieldChange}
                value={this.state.message}/>
              <br />
            </FormGroup>
            {this.sentMessage()}
            <ConditionalButton
              type="submit"
              text={this.t('send')}
              condition={() => this.canSend()}
            />
          </form>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  sentMessage() {
    if (this.state.sent) {
      return <EnquirySent queued={this.props.electron}/>;
    } else {
      return null;
    }
  }
}

Contact.contextType = TranslationContext;

export default Contact;
