// A class for generating random identifiers.
class IdGenerator {
  // Returns a random itentifier or length len.
  generate(len = 8) {
    let set = this.base58();
    var id = '';
    for (var i = 0; i < len; i++) {
      id += set.charAt(Math.floor(Math.random() * set.length));
    }
    return id;
  }

  // Returns a Base58 character set.
  base58() {
    return '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
  }
}

export default IdGenerator;
