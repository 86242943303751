export function tr(str) {
  return rot13And5Fast(str);
}

export function detr(str) {
  return rot13And5Fast(str);
}

function rot13And5Fast(str) {
  if (!rot13And5Fast.input) {
    rot13And5Fast.input  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.split('')
    rot13And5Fast.output = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm5678901234'.split('')
    rot13And5Fast.lookup = rot13And5Fast.input.reduce((m,k,i) => Object.assign(m, {[k]: rot13And5Fast.output[i]}), {})
  }

  return str.split('').map(x => rot13And5Fast.lookup[x] || x).join('')
}
