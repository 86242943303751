import React, { Component } from 'react';

import BackButton from '../components/BackButton';
import ConditionalButton from '../components/ConditionalButton';
import Dimension from './Dimension';
import ElementExpertResults from './ElementExpertResults';
import ParFit from '../components/ParFit';
import PrimaryPanel from '../components/panels/PrimaryPanel';
import SecondaryPanel from '../components/panels/SecondaryPanel';

import './Dimensions.css';

import { TranslationContext } from '../translations/TranslationContext';

export default class Dimensions extends Component {
  constructor() {
    super();

    this.state = {
      dimensions: {
        'A': '', 'B': '', 'C': '',
        'D': '', 'E': '', 'F': '',
        'G': '', 'H': '', 'I': '',
        'J': '', 'K': ''
      },
      results: null,
      searching: false,
      style: null,
      styleImages: null,
      tolerances: {
        'A': 3, 'B': 3, 'C': 3,
        'D': 3, 'E': 3, 'F': 3,
        'G': 3, 'H': 3, 'I': 3,
        'J': 3, 'K': 3
      }
    };
  }

  t = (id) => this.context.translate('element_expert.dimensions.' + id);

  componentDidMount() {
    const id = this.props.id || this.props.match.params.id;
    this.props.elementExpert.fetchStyle(id)
      .then(style => this.setState({style}));
    this.props.styleImages.fetchStyleImages(id)
      .then(styleImages => {
        console.log(styleImages);
        this.setState({styleImages})
      });
  }

  render() {
    if (this.state.results !== null) {
      return (
        <ElementExpertResults
          results={this.state.results}
          onBack={() => this.onResultsBack()}
          onLoadMore={() => this.loadMore()}
          datasheets={this.props.datasheets}
          elementExpert={this.props.elementExpert}
          elementImages={this.props.elementImages}
          typeImages={this.props.typeImages}
          enquiries={this.props.enquiries}
        />
      );
    } else {
      return (
        <div className="Dimensions">
          <PrimaryPanel>
            <BackButton onClick={() => this.props.onBack()}/>
            <ParFit/>
            <h1>{this.t('element_expert')}</h1>
            <p>
              {this.t('intro')}
            </p>
            <form onSubmit={(e) => { e.preventDefault(); this.searchClicked(); }}>
              {this.dimensions()}
              <ConditionalButton
                type="submit"
                condition={() => this.canSearch()}
                text={this.t('search')}
                loading={false}
                />
            </form>
          </PrimaryPanel>
          <SecondaryPanel>
            <h2>{this.t('line_drawing')}</h2>
            {this.lineDrawingImage()}
          </SecondaryPanel>
        </div>
      );
    }
  }

  dimensions() {
    let components = [];

    if (this.state.style === null) {
      return components;
    }

    let letters = this.state.style.dimensions.split(',')
                                             .map(d => d.toUpperCase());

    for (let letter of letters) {
      components.push(
        <Dimension
          key={letter}
          letter={letter}
          label={this.label(letter)}
          onDimensionChange={(letter, value) => this.dimensionChanged(letter, value)}
          onToleranceChange={(letter, value) => this.toleranceChanged(letter, value)}
          dimension={this.state.dimensions[letter]}
          tolerance={this.state.tolerances[letter]}
        />
      );
    }

    return components;
  }

  label(letter) {
    let suffix = ' (mm)';
    if (this.state.style.division_name === 'Hydraulic') {
      if (letter === 'A' || letter === 'E') {
        suffix += '*';
      }
      return this.hifeLabel(letter) + suffix;
    } else {
      return this.emfeLabel(letter) + suffix;
    }
  }

  hifeLabel(letter) {
    return this.t('hife.' + letter);
  }

  emfeLabel(letter) {
    return this.t('emfe.' + letter);
  }

  canSearch() {
    return this.numberOfDimensionsFilled() >= 1 && this.hydraulicMandatoryFieldsFilled();
  }

  numberOfDimensionsFilled() {
    const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
    let number = 0;
    for (let letter of letters) {
      if (this.dimensionFilled(letter)) {
        number ++;
      }
    }
    return number;
  }

  dimensionFilled(letter) {
    let value = this.state.dimensions[letter].trim();
    return value !== '' && parseInt(value).toString() === value;
  }

  hydraulicMandatoryFieldsFilled() {
    if (this.state.style.division_name !== 'Hydraulic') {
      return true;
    }
    return (!this.hasDimension('A') || this.dimensionFilled('A')) && this.dimensionFilled('E');
  }

  hasDimension = (letter) =>
    this.state.style.dimensions.toUpperCase().indexOf(letter.toUpperCase()) !== -1;

  dimensionChanged(letter, value) {
    let dimensions =  Object.assign({}, this.state.dimensions);
    dimensions[letter] = value;
    this.setState({
      dimensions: dimensions
    });
  }

  toleranceChanged(letter, value) {
    let tolerances =  Object.assign({}, this.state.tolerances);
    tolerances[letter] = value;
    this.setState({
      tolerances: tolerances
    });
  }

  searchClicked() {
    this.setState({ searching: true });

    this.props.elementExpert.search(this.props.id || this.props.match.params.id, this.state.dimensions, this.state.tolerances)
      .then(elements => {
        this.setState({
          searching: false,  results: { data: elements, visible: 15 }
        });
        return this.addDatasheetUrls();
      }).catch(error => console.error(error));
  }

  addDatasheetUrls() {
    return this.props.datasheets.addUrls(this.state.results.data)
      .then(matchedData =>
        this.setState({
          results: Object.assign(this.state.results, { data: matchedData })
        })
      ).catch(error => console.error(error));
  }

  onResultsBack() {
    this.setState({ results: null });
  }

  loadMore() {
    this.setState({
      results: {
        data: this.state.results.data,
        visible: this.state.results.visible + 15
      }
    });
  }
  lineDrawingImage() {
    if (this.state.styleImages === null) {
      return null;
    } else {
      return (
        <div className="Dimensions-line-drawing">
          <img src={this.state.styleImages.lineDrawingUrl} alt=""/>
        </div>
      );
    }
  }
}

Dimensions.contextType = TranslationContext;
