import React, { Component } from 'react';
import ProductStatus from './ProductStatus';
import './ProductInfo.css';

import { TranslationContext } from '../translations/TranslationContext';

class EmfeProductInfo extends Component {

  t = (id) => this.context.translate('product_info.emfe_product_info.' + id);

  render() {

    let outerDiameter = null;
    if (this.props.element.dimension_b) {
      outerDiameter = (
        <tr>
          <th>{this.t('outer_diameter')}</th>
          <td>{this.props.element.dimension_b} {this.t('mm')}</td>
        </tr>
      );
    }
    let centreThread = null;
    if (this.props.element.dimension_c_text) {
      centreThread = (
        <tr>
          <th>{this.t('centre_thread')}</th>
          <td>{this.props.element.dimension_c_text}</td>
        </tr>
      );
    }
    return (

      <div className="EmfeProductInfo">
        <h1 className="ProductInfo-parker-number">{this.props.element.parker_number}</h1>

        <ProductStatus element={this.props.element} />

        <h2>{this.t('product_features')}</h2>
        <table className="ProductInfo-table">
          <tbody>
            <tr>
              <th>{this.t('element_style')}</th>
              <td>{this.props.element.element_style}</td>
            </tr>
            <tr>
              <th>{this.t('type')}</th>
              <td>{this.props.element.style_name}</td>
            </tr>
            <tr>
              <th>{this.t('height')}</th>
              <td>{this.props.element.dimension_a} {this.t('mm')}</td>
            </tr>
            {outerDiameter}
            {centreThread}
            <tr>
              <th>{this.t('nominal_flow_rate')}</th>
              <td>{this.props.element.nominal_flow_rate} {this.t('lph')}</td>
            </tr>
            <tr>
              <th>{this.t('micron_rating')}</th>
              <td>{this.props.element.media}</td>
            </tr>
            <tr>
              <th>{this.t('notes')}</th>
              <td>{this.props.element.notes}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

EmfeProductInfo.contextType = TranslationContext;

export default EmfeProductInfo;
