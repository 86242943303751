import React, { Component } from 'react';

import Style from './Style';
import StyleImage from './StyleImage';

import './FiltrationType.css';

export default class FiltrationType extends Component {
  render() {
    return(
      <div className="FiltrationType">
        <h2 className="FiltrationType-heading">{this.props.name}</h2>
        <div className="FiltrationType-styles">
          {this.styles()}
        </div>
      </div>
    );
  }

  styles() {
    let components = [];
    for (let style of this.props.styles) {
      components.push(
        <Style
          key={style.id} id={style.id} name={style.name}
          styleImage={this.styleImage(style.id)}
          onStyleSelected={(id) => this.props.onStyleSelected(id)}
          />
      );
    }
    return components;
  }

  styleImage(id) {
    return <StyleImage id={id} styleImages={this.props.styleImages} />;
  }
}
