import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from './components/header/Header';
import HeaderMinimal from './components/header/HeaderMinimal';

import Bootstrap from './Bootstrap';
import ResourceDownloader from './ResourceDownloader';

import Contact from './contact/Contact';

import Datasheets from './datasheets/Datasheets';
import DatasheetRepository from './data/DatasheetRepository';
import DatasheetRepositoryLocal from './data/DatasheetRepositoryLocal';

import IdGenerator from './IdGenerator';
import Enquiries from './enquiries/Enquiries';
import EnquiriesModel from './enquiries/EnquiriesModel';
import CannotFind from './cannot_find/CannotFind';

import Notifications from './notifications/Notifications';
import NotificationsModel from './notifications/NotificationsModel';

import Updates from './updates/Updates';
import UpdateHelper from './updates/UpdateHelper';

import Auth from './account/Auth';
import EngineerRepository from './account/EngineerRepository';
import Register from './account/Register';
import ResetPassword from './account/ResetPassword';
import ResetPasswordEmail from './account/ResetPasswordEmail';
import SignIn from './account/SignIn';
import SignInOrReset from './account/SignInOrReset';
import VideoTutorial from './video_tutorial/VideoTutorial';
import Information from './information/Information';
import Language from './language/Language';

import Home from './home/Home';
import ElementExpert from './element_expert/ElementExpert';
import Dimensions from './element_expert/Dimensions';
import ElementSelector from './element_selector/ElementSelector';
import OEMApps from './oem_apps/OEMApps';
import MediaInfo from './media_info/MediaInfo';
import ProductInfo from './product_info/ProductInfo';
import TypeViewer from './type_viewer/TypeViewer';

// Data providers.
// idb replaces the weird IDBRequest objects with promises which makes it easier
// to keep our repository APIs consistent.
import idb from 'idb';
import API from './data/API';
import { initialiseDatabase } from './data/IndexedDB';
import BackgroundQueue from './data/BackgroundQueue';
// Each pair of repositories conform to the same interface.
import ElementExpertRepository from './data/ElementExpertRepository';
import ElementExpertRepositoryLocal from './data/ElementExpertRepositoryLocal';
import ElementSelectorRepository from './data/ElementSelectorRepository';
import ElementSelectorRepositoryLocal from './data/ElementSelectorRepositoryLocal';
import OEMRepository from './data/OEMRepository';
import OEMRepositoryDB from './data/OEMRepositoryDB';
import ProductRepository from './data/ProductRepository';
import ProductRepositoryDB from './data/ProductRepositoryDB';
import ElementImageRepository from './data/ElementImageRepository';
import ElementImageRepositoryLocal from './data/ElementImageRepositoryLocal';
import StyleImageRepository from './data/StyleImageRepository';
import StyleImageRepositoryLocal from './data/StyleImageRepositoryLocal';
import TypeImageRepository from './data/TypeImageRepository';
import TypeImageRepositoryLocal from './data/TypeImageRepositoryLocal';

import { TranslationContext } from './translations/TranslationContext';
import Translation from './translations/Translation';
import Translations from './translations/Translations';
import TranslationsLocalStorage from './translations/TranslationsLocalStorage';

import './App.css';

import {version} from '../package.json';

class App extends Component {
  constructor() {
    super();

    this.onUnauthorized = this.onUnauthorized.bind(this);

    this.api = new API(this.onUnauthorized);
    this.backgroundQueue = new BackgroundQueue(
      this.api, this.localStorage(), 15
    );

    let enquiries = new EnquiriesModel(new IdGenerator(), this.localStorage()),
        notifications = new NotificationsModel(this.api);

    enquiries.onChange = (e) => this.onEnquiriesChange(e);
    notifications.onChange = (e) => this.onNotificationsChange(e);

    this.auth = new Auth(new EngineerRepository(this.localStorage()), this.api);

    if (this.auth.signedIn()) {
      notifications.fetchNotifications();
    }

    this.state = {
      translation: new Translation(),
      token: this.auth.getToken(),
      enquiries: enquiries,
      notifications: notifications,
      bootstrapped: true,
      db: null,
      // We start off with web-backed repositories but will replace them
      // shortly with IndexedDB-backed ones if we happen to be running in
      // Electron.
      repos: this.onlineRepositories(this.api)
    };

    if (this.isElectron()) {
      // We're not bootstrapped for offline yet, so set this to render the
      // bootrapping component.
      this.state.bootstrapped = this.isBootstrapped();

      // Open up our local IndexedDB.
      this.state.db = initialiseDatabase(idb);
    }
  }

  isBootstrapped() {
    return this.localStorage().getItem('bootstrapVersion') === version;
  }

  bootstrapped() {
    this.localStorage().setItem('bootstrapVersion', version);
    this.setState({bootstrapped: true});
  }

  componentDidMount() {
    const locale = this.localStorage().getItem('locale') || 'en_GB';
    console.log('setting initial locale', locale);
    this.setState({ locale })

    if (this.isElectron()) {
      const ipc = window.require('electron').ipcRenderer;
      ipc.once('get_user_data_path', (_, path) => {
        // Replace our online repositories with local database- and
        // filesystem-backed ones.
        const unixStylePath = this.convertBackslashesToForwardSlashes(path);
        let offlineRepositories = this.offlineRepositories(this.api, this.state.db, unixStylePath);
        this.setState({
          updateHelper: new UpdateHelper(this.api, this.state.db, offlineRepositories, this.getResourceDownloader()),
          repos: offlineRepositories,
          userDataPath: unixStylePath
        });
        // loadTranslation needs the translations repo from our newly set but
        // not yet available state. Wait a tick, then load it.
        setTimeout(() => this.loadTranslation(locale), 1);
      });
      ipc.send('get_user_data_path');
    } else {
      this.loadTranslation(locale);
    }
  }

  convertBackslashesToForwardSlashes = string => string.replace(/\\/g, '/');

  loadTranslation(locale) {
    console.log('loadTranslation', locale);
    this.state.repos.translations
      .fetchTranslation(locale)
      .then(translation => this.setState({ translation: new Translation(translation) }))
      .catch((error) => {
        alert('Translations could not be loaded. Using English.')
      });
  }

  onSetLocale(locale) {
    console.log('onSetLocale', locale);
    this.localStorage().setItem('locale', locale);
    this.setState({ locale });
    this.loadTranslation(locale);
  }

  localStorage() {
    return window.localStorage || {
      getItem: function (key) {
        return this[key];
      },
      setItem: function (key, value) {
        this[key] = value;
      },
      removeItem: function (key) {
        this[key] = null;
      }
    };
  }

  isElectron() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf(' electron/') > -1;
  }

  // Returns a set of repositories that are backed by the web API. These
  // require an Internet connection.
  onlineRepositories(api) {
    return {
      datasheets: new DatasheetRepository(api),
      elementExpert: new ElementExpertRepository(api),
      elementImages: new ElementImageRepository(api),
      elementSelector: new ElementSelectorRepository(api),
      oems: new OEMRepository(api),
      products: new ProductRepository(api),
      styleImages: new StyleImageRepository(api),
      translations: new Translations(api),
      typeImages: new TypeImageRepository(api)
    };
  }

  // Returns IndexDB database-backed repositories for use with Electron.
  // db is an IDB (promise wrapped) IDBDatabase interface.
  offlineRepositories(api, db, userDataPath) {
    return {
      datasheets: new DatasheetRepositoryLocal(db, userDataPath),
      elementExpert: new ElementExpertRepositoryLocal(db),
      elementImages: new ElementImageRepositoryLocal(db, userDataPath),
      elementSelector: new ElementSelectorRepositoryLocal(db),
      oems: new OEMRepositoryDB(db),
      products: new ProductRepositoryDB(db),
      styleImages: new StyleImageRepositoryLocal(db, userDataPath),
      translations: new TranslationsLocalStorage(this.localStorage(), api),
      typeImages: new TypeImageRepositoryLocal(db, userDataPath)
    };
  }

  onEnquiriesChange(enquiries) {
    this.setState({ enquiries: enquiries });
  }

  onNotificationsChange(notifications) {
    this.setState({ notifications: notifications });
  }

  onUnauthorized() {
    this.onSignOut();
  }

  onSignIn(token) {
    this.state.notifications.fetchNotifications();
    this.setState({
      token: token
    });
  }

  onSignOut() {
    this.auth.setToken(null);
    this.setState({
      token: null
    });
  }

  getResourceDownloader() {
    if (!this.getResourceDownloader.downloader) {
      const ipc = window.require('electron').ipcRenderer;
      this.getResourceDownloader.downloader =
        new ResourceDownloader(ipc, this.api, this.state.db);
    }
    return this.getResourceDownloader.downloader;
  }

  render() {
    if (this.state.bootstrapped || !this.state.token) {
      return (
        <TranslationContext.Provider value={this.state.translation}>
          {this.app()}
        </TranslationContext.Provider>
      );
    } else if (this.state.db && this.state.userDataPath) {
      return(
        <TranslationContext.Provider value={this.state.translation}>
          <Bootstrap
            api={this.api}
            db={this.state.db}
            repos={this.state.repos}
            updateHelper={this.state.updateHelper}
            onComplete={() => this.bootstrapped()}
          />
        </TranslationContext.Provider>
      );
    } else {
      return null;
    }
  }

  app() {
    if (this.state.token) {
      return this.appSignedIn();
    } else {
      return this.appSignedOut();
    }
  }

  appSignedIn() {
    const electron = this.isElectron();
    let className = 'App';
    if (electron) {
      className += ' App-electron';

      if (!this.state.userDataPath) {
        // We're still getting some required info from Electron but we'll be
        // ready to render very soon.
        return null;
      }
    }

    return (
      <div className={className}>
        <Header
          enquiries={this.state.enquiries}
          showUpdates={electron}
          notifications={this.state.notifications}
          updateHelper={this.state.updateHelper}
        />
        <Route exact path="/" render={(routeProps) => (
          <Home {...routeProps}
            onSignOut={() => this.onSignOut()}
          />
        )}/>
        <Route path="/contact" render={(routeProps) => (
          <Contact {...routeProps}
            backgroundQueue={this.backgroundQueue}
            electron={electron}
          />
        )}/>

        <Route path="/enquiries" render={(routeProps) => (
          <Enquiries {...routeProps}
            backgroundQueue={this.backgroundQueue}
            enquiries={this.state.enquiries}
            electron={electron}
          />
        )}/>

        <Route path="/cannot-find" render={(routeProps) => (
          <CannotFind {...routeProps}
            backgroundQueue={this.backgroundQueue}
            electron={electron}
          />
        )}/>

        <Route path="/notifications" render={(routeProps) => (
          <Notifications {...routeProps}
            notifications={this.state.notifications}
          />
        )}/>

        <Route path="/updates" render={(routeProps) => (
          <Updates {...routeProps}
            updateHelper={this.state.updateHelper}
          />
        )}/>

        <Route path="/element-selector" render={(routeProps) => (
          <ElementSelector {...routeProps}
            datasheets={this.state.repos.datasheets}
            elementExpert={this.state.repos.elementExpert}
            elementImages={this.state.repos.elementImages}
            elementSelector={this.state.repos.elementSelector}
            enquiries={this.state.enquiries}
          />
        )}/>

        <Route exact path="/element-expert" render={(routeProps) => (
          <ElementExpert {...routeProps}
            datasheets={this.state.repos.datasheets}
            elementExpert={this.state.repos.elementExpert}
            elementImages={this.state.repos.elementImages}
            styleImages={this.state.repos.styleImages}
            typeImages={this.state.repos.typeImages}
            enquiries={this.state.enquiries}
          />
        )}/>
        <Route path="/element-expert/:id/dimensions" render={(routeProps) => (
          <Dimensions {...routeProps}
            datasheets={this.state.repos.datasheets}
            enquiries={this.state.enquiries}
            elementExpert={this.state.repos.elementExpert}
            elementImages={this.state.repos.elementImages}
            styleImages={this.state.repos.styleImages}
          />
        )}/>

        <Route path="/oem-apps" render={(routeProps) => (
          <OEMApps {...routeProps}
            repos={this.state.repos}
            enquiries={this.state.enquiries}
          />
        )}/>

        <Route path="/product-info/:parkerNumber" render={(routeProps) => (
          <ProductInfo {...routeProps}
            datasheets={this.state.repos.datasheets}
            elementExpert={this.state.repos.elementExpert}
            elementImages={this.state.repos.elementImages}
            enquiries={this.state.enquiries}
          />
        )}/>

        <Route path="/type-viewer/:id" render={(routeProps) => (
          <TypeViewer {...routeProps}
            typeImages={this.state.repos.typeImages}
          />
        )}/>

        <Route path="/media-info/:parkerNumber" render={(routeProps) => (
          <MediaInfo {...routeProps}
            elementExpert={this.state.repos.elementExpert}
          />
        )}/>

        <Route path="/datasheets" render={(routeProps) => (
          <Datasheets {...routeProps} datasheets={this.state.repos.datasheets} />
        )}/>

        <Route path="/video-tutorial" component={VideoTutorial}/>
        <Route path="/information" component={Information}/>
        <Route path="/language" render={(routeProps) => (
          <Language {...routeProps}
            locale={this.state.locale}
            onSetLocale={(locale) => this.onSetLocale(locale)}
            />
        )}/>
      </div>
    );
  }

  appSignedOut() {
    return (
      <div className="App">
        <HeaderMinimal/>
        <Switch>
          <Route path="/account/sign-in-or-reset" component={SignInOrReset}/>
          <Route path="/account/register" render={(routeProps) => (
            <Register {...routeProps}
              api={this.api}
            />
          )}/>
          <Route path="/account/reset-password/:email" render={(routeProps) => (
            <ResetPasswordEmail {...routeProps} />
          )}/>
          <Route path="/account/reset-password" render={(routeProps) => (
            <ResetPassword {...routeProps}
              api={this.api}
            />
          )}/>
          <Route path="/language" render={(routeProps) => (
            <Language {...routeProps}
              locale={this.state.locale}
              onSetLocale={(locale) => this.onSetLocale(locale)}
              />
          )}/>
          <Route render={(routeProps) => (
            <SignIn {...routeProps}
              auth={this.auth}
              onSignIn={(token) => this.onSignIn(token)}
            />
          )}/>
        </Switch>
      </div>
    );
  }
}

export default App;
