import $ from 'jquery';

// Wrapper for network calls to the web API endpoints.

class API {
  constructor(onUnauthorized) {
    this.onUnauthorized = onUnauthorized;
    this.token = null;
    if (process.env.NODE_ENV === 'production') {
      this.siteBase = 'https://cms.parfittoolkit.com/';
    } else if (process.env.NODE_ENV === 'staging') {
      this.siteBase = 'https://parker-toolkit-cms.f-ox.co/';
    } else {
      this.siteBase = 'http://localhost:8000/';
    }
    this.apiBase = this.siteBase + 'api/v1/';
  }

  setToken(token) {
    this.token = token;
  }

  hasToken() {
    return this.token !== null;
  }

  login(email, password) {
    return this.post('auth/login', { email: email, password: password });
  }

  register(data) {
    return this.post('engineers', data);
  }

  resetPassword(email) {
    return this.post('engineers/reset-password', { email: email });
  }

  elementSelectorSearch(queries) {
    let path = 'competitor_parts/search?queries=' +
      encodeURIComponent(queries.toString());
    return this.get(path);
  }

  fetchStatus = (collection) => this.get(collection + '/status')

  fetchCompetitors = () => this.get('competitors');

  fetchCompetitorParts = () => this.get('competitor_parts');

  fetchDatasheets = () => this.fetchResources('datasheets');

  fetchNotifications() {
    return this.get('notifications');
  }

  fetchOEMs() {
    return this.get('oems');
  }

  fetchOEM(id) {
    return this.get('oems/' + id);
  }

  fetchProducts = () => this.get('products');

  fetchModelsForOEM(oemId) {
    return this.get('products/models/' + oemId);
  }

  fetchProductsForOEMAndModel(oemId, model) {
    return this.get('products/oem/' + oemId + '/' + encodeURIComponent(model));
  }

  fetchProductsForOEMPartNumber(partNumber) {
    return this.get('products/oem-number/' + encodeURIComponent(partNumber));
  }

  fetchProductsForParkerPartNumber(partNumber) {
    return this.get('products/parker-number/' + encodeURIComponent(partNumber));
  }

  elementExpertSearch(styleId, dimensions, tolerances) {
    let path = 'elements/search?style_id=' +
      encodeURIComponent(styleId);
    const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
    for (let letter of letters) {
      path += '&dimensions[' + letter + ']=' + encodeURIComponent(dimensions[letter]);
      path += '&tolerances[' + letter + ']=' + encodeURIComponent(tolerances[letter]);
    }
    return this.get(path);
  }

  fetchFiltrationTypes = () => this.get('filtration_types');

  fetchStyles = () => this.get('styles');

  fetchStyleImages = () => this.fetchResources('styles');

  fetchTypeImages = () => this.fetchResources('types');

  fetchElements = () => this.get('elements');

  fetchElement = (parkerNumber) => this.get('elements/' + parkerNumber);

  fetchElementImages = () => this.fetchResources('elements');

  fetchTranslation = (locale) => this.get('translations/' + locale);

  fetchResources = (collection) => this.get('resources/' + collection);

  // Sends a simple request to the server to see if it is reachable.
  ping() {
    return this.get('ping');
  }

  get(path) {
    const url = this.apiBase + path;
    const api = this;
    return Promise.resolve($.ajax({
      headers: api.headers(),
      url: url,
      method: 'GET',
      dataType: 'json'
    })).catch(function(error) {
      if (error.status === 401) {
        api.onUnauthorized();
      }
      // TODO: Just throw error and change clients to find responseJSON on their
      // own if they need it; this means we only return one error type.
      throw(error.responseJSON || error);
    });
  }

  post(path, data) {
    const url = this.apiBase + path;
    const api = this;
    return Promise.resolve($.ajax({
      headers: api.headers(),
      url: url,
      method: 'POST',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      data: JSON.stringify(data)
    })).catch(function(error) {
      if (error.status === 401) {
        api.onUnauthorized();
      }
      throw(error.responseJSON);
    });
  }

  headers() {
    if (!this.token) {
      return {};
    }
    return {
      'Authorization': 'Bearer ' + this.token.access_token
    };
  }
}

export default API;
