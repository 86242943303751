import React, { Component } from 'react';

import MenuItem from './MenuItem';

import './Menu.css';

import { TranslationContext } from '../../translations/TranslationContext';

class Menu extends Component {
  t = (id) => this.context.translate('components.header.menu.' + id);

  render() {
    return (
      <div className="Menu" onClick={this.props.onClick}>
        <ul>
          <MenuItem label={this.t('home')} to="/" badgeCount="0"/>
          <MenuItem label={this.t('element_selector')} to="/element-selector" badgeCount="0"/>
          <MenuItem label={this.t('element_expert')} to="/element-expert" badgeCount="0"/>
          <MenuItem label={this.t('oem_apps')} to="/oem-apps" badgeCount="0"/>
          <MenuItem label={this.t('datasheets')} to="/datasheets" badgeCount="0"/>
          <MenuItem label={this.t('notifications')} to="/notifications" badgeCount={this.props.notifications}/>
          <MenuItem label={this.t('enquiries')} to="/enquiries" badgeCount={this.props.enquiries}/>
          <MenuItem label={this.t('contact')} to="/contact" badgeCount="0"/>
          <MenuItem label={this.t('cannot_find')} to="/cannot-find" badgeCount="0"/>
          <MenuItem label={this.t('video_tutorial')} to="/video-tutorial" badgeCount="0"/>
          <MenuItem label={this.t('language')} to="/language" badgeCount="0"/>
          <MenuItem label={this.t('information')} href="http://www.parker.com/portal/site/PARKER/menuitem.4450f18f18c082cdfd40eae8237ad1ca/?vgnextoid=760b904cf58b2110VgnVCM100000c9040d0aRCRD&amp;vgnextfmt=EN" badgeCount="0"/>
        </ul>
      </div>
    );
  }
};

Menu.contextType = TranslationContext;

export default Menu;
