import Updater from './Updater';

export default class FiltrationTypeUpdater extends Updater {
  dependent = false;
  name = 'Filtration types';
  collection = 'filtration_types';
  store = 'filtrationTypes';

  update() {
    return this.api.fetchFiltrationTypes()
      .then(data =>
        this.clearStore().then(_ => this.storeData(data))
      );
  }
}
