import React from 'react';
import PropTypes from 'prop-types';

import './FormGroup.css';

const FormGroup = (props) => {
  let className = 'FormGroup';
  if (props.highlighted) {
    className += ' FormGroup-highlighted';
  }
  return (
    <div className={className}>
      {props.children}
    </div>
  );
}

FormGroup.propTypes = {
  highlighted: PropTypes.bool
};

export default FormGroup;
