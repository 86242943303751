import React, { Component } from 'react';
import FullPanel from './components/FullPanel';
import ParFit from './components/ParFit';
import UpdateStatus from './updates/UpdateStatus';

import { TranslationContext } from './translations/TranslationContext';

class Bootstrap extends Component {
  state = {
    competitorPercent: 0,
    competitorPartPercent: 0,
    elementPercent: 0,
    error: null,
    filtrationTypePercent: 0,
    oemPercent: 0,
    productPercent: 0,
    stylePercent: 0,

    datasheetPercent: 0,
    elementImagePercent: 0,
    styleImagePercent: 0,
    typeImagePercent: 0,
  };

  t = (id) => this.context.translate('bootstrap.' + id);

  componentDidMount() {
    const independents = this.props.updateHelper.updaters()
      .filter((updater) => !updater.dependent)
      .map((updater) => Promise.resolve(updater.bootstrap()));

    const dependents = this.props.updateHelper.updaters()
      .filter((updater) => updater.dependent)
      .map((updater) => updater.bootstrap.bind(updater));

    Promise
      .all(independents)
      .then(() => this.promiseSerial(dependents))
      .then(() => this.props.onComplete())
      .catch((error) => {
        console.error(error);
        this.setState({ error })
      });
  }

  // Helper to run a number of asynchronous operations in sequence.
  // Since our sequence is a list of write transactions on the same object
  // store there is no benefit in running them in parallel.
  promiseSerial = funcs =>
    funcs.reduce((promise, func) =>
      promise.then(result => func().then(Array.prototype.concat.bind(result))),
      Promise.resolve([]))

  render() {
    if (this.state.error) {
      return (
        <FullPanel>
          <ParFit/>
          <h1>{this.t('error_occurred')}</h1>
          <p>
            {this.t('error_occurred_detail')}
          </p>
        </FullPanel>
      );
    }
    return (
      <FullPanel>
        <ParFit/>
        <h1>{this.t('getting_ready')}</h1>
        <p>
          {this.t('getting_ready_detail')}
        </p>
        {this.props.updateHelper.updaters().map((updater) =>
          <UpdateStatus key={updater.name} updater={updater}/>
        )}
      </FullPanel>
    );
  }
}

Bootstrap.contextType = TranslationContext;

export default Bootstrap;
