// A repository for products backed by a the web API.
class ProductRepository {
  constructor(api) {
    this.api = api;
  }

  modelsForOEM(oemId) {
    if (!oemId) {
      return new Promise(function(resolve, reject) {
        resolve([]);
      });
    }

    return this.api.fetchModelsForOEM(oemId).then(function(data) {
      return data.models.sort();
    });
  }

  forOEMAndModel(oemId, model) {
    return this.api.fetchProductsForOEMAndModel(oemId, model).then(function(data) {
      return data.products.filter(p => p.model === model);
    });
  }

  forOEMPartNumber(partNumber) {
    return this.api.fetchProductsForOEMPartNumber(partNumber).then(function(data) {
      return data.products;
    });
  }

  forParkerPartNumber(partNumber) {
    return this.api.fetchProductsForParkerPartNumber(partNumber).then(function(data) {
      return data.products;
    });
  }
}

export default ProductRepository;
