import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import AddFieldButton from '../components/AddFieldButton';
import BackButton from '../components/BackButton';
import ConditionalButton from '../components/ConditionalButton';
import EmptyPanel from '../components/panels/EmptyPanel';
import Intro from '../components/Intro';
import ParFit from '../components/ParFit';
import RemovableInputField from '../components/RemovableInputField';
import PrimaryPanel from '../components/panels/PrimaryPanel';
import { TranslationContext } from '../translations/TranslationContext';
import cleanQuery from '../CleanQuery.js';

import './ElementSelector.css';

const background = require('./element-selector-bg.jpg');

class ElementSelectorSearch extends Component {
  constructor(props) {
    super(props);

    let fieldId = -1;
    let queries = {};
    let suggestions = {};

    if (!props.queries.length) {
      props.queries.push('');
    }

    for (let q in props.queries) {
      fieldId += 1;
      queries[fieldId] = props.queries[q];
      suggestions[fieldId] = [];
    }

    this.state = {
      fieldId: fieldId,
      queries: queries,
      suggestions: suggestions
    };
  }

  t = (id) => this.context.translate('element_selector.element_selector_search.' + id);

  onAddField(value = '') {
    let fieldId = this.state.fieldId + 1;
    let queries = Object.assign({}, this.state.queries);
    queries[fieldId] = '';
    let suggestions = Object.assign({}, this.state.suggestions);
    suggestions[fieldId] = [];
    this.setState({
      fieldId: fieldId,
      queries: queries
    });
  }

  onFieldChange(id, value) {
    let queries = Object.assign({}, this.state.queries);
    queries[id] = value.toUpperCase();
    this.setState({ queries: queries });
  }

  onRemoveField(id) {
    let queries = Object.assign({}, this.state.queries);
    delete queries[id];
    let suggestions = Object.assign({}, this.state.suggestions);
    delete suggestions[id];
    this.setState({ queries, suggestions });
  }

  canSearch() {
    return this.queries().length > 0;
  }

  onSearch() {
    this.props.onSearch(this.queries());
  }

  queries() {
    let queries = [];
    for (let key in this.state.queries) {
      let query = cleanQuery(this.state.queries[key]);
      if (query.length > 0) {
        queries.push(query);
      }
    }
    return queries;
  }

  render() {
    return (
      <div className="ElementSelectorSearch">
        <PrimaryPanel>
          <Route render={({history}) => (
            <BackButton onClick={() => { history.push('/') }}/>
          )} />
          <ParFit/>
          <h1>{this.t('element_selector')}</h1>
          <Intro>
            {this.t('intro')}
          </Intro>

          <form onSubmit={(e) => { e.preventDefault(); this.onSearch(); }}>
            {this.queryFields()}
            {this.addFieldButton()}

            <div>
              <ConditionalButton
                type="submit"
                text={this.t('search')}
                loading={this.state.loading}
                condition={() => this.canSearch()}
              />
            </div>
          </form>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  queryFields() {
    let fields = [];
    for (let key in this.state.queries) {
      fields.push(
        <RemovableInputField
          key={key}
          id={key}
          onChange={(i, v) => { this.onFieldChange(i, v) }}
          options={this.state.suggestions[key]}
          onSearch={query => this.getSuggestions(key, query) }
          onRemove={(id) => { this.onRemoveField(id) }}
          value={this.state.queries[key]}
          filterBy={(option, props) => { return cleanQuery(option).indexOf(cleanQuery(props.text)) === 0 }}
        />
      );
    }
    return fields;
  }

  getSuggestions(key, query) {
    const clean = cleanQuery(query);
    if (clean.length < 3) {
      return;
    }
    this.props.elementSelector.search([clean])
      .then(competitor_parts => {
        let suggestions = Object.assign({}, this.state.suggestions);
        let maybeDuplicates = competitor_parts.map(cp => cp.competitor_number).slice(0, 5);
        suggestions[key] = [...new Set(maybeDuplicates)]
        this.setState({ suggestions });
      }).catch(function(error) {
        console.error(error);
      });
  }

  addFieldButton() {
    if (Object.keys(this.state.queries).length < 6) {
      return <AddFieldButton onAdd={() => { this.onAddField() }}/>;
    } else {
      return null;
    }
  }
}

ElementSelectorSearch.contextType = TranslationContext;

export default ElementSelectorSearch;
