import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ViewButton.css';
import magnify from './magnify.svg';

import { TranslationContext } from '../translations/TranslationContext';

class ViewTypeButton extends Component {

  t = (id) => this.context.translate('components.view_type_button.' + id);

  render() {
    return(
      <button className="ViewButton DefaultButton"
        onClick={() => this.props.onClick(this.props.elementType)}>
        {this.t('view')}}&nbsp;<img src={magnify} alt=""/>
      </button>
    );
  }
}

ViewTypeButton.propTypes = {
  elementType: PropTypes.number.isRequired
};


ViewTypeButton.contextType = TranslationContext;

export default ViewTypeButton;
