import React, { Component } from 'react';

import LabeledInputField from '../components/LabeledInputField';
import LabeledSelectField from '../components/LabeledSelectField';

import { TranslationContext } from '../translations/TranslationContext';

import './Dimensions.css';

class Dimension extends Component {
  render() {
    const label = `${this.props.letter}. ${this.props.label}`;
    return (
      <div className="Dimension">
        <LabeledInputField
          id={this.props.letter}
          label={label}
          value={this.props.dimension}
          onChange={this.props.onDimensionChange}
        />
        <LabeledSelectField
          id={`TOL-${this.props.letter}`}
          label={this.t('tolerance')}
          value={this.props.tolerance}
          onChange={(id, val) => this.props.onToleranceChange(id.replace('TOL-', ''), val)}>
          <option value="1">1 mm</option>
          <option value="2">2 mm</option>
          <option value="3">3 mm</option>
          <option value="4">4 mm</option>
          <option value="5">5 mm</option>
        </LabeledSelectField>
      </div>
    );
  }

  t = (id) => this.context.translate('element_expert.dimension.' + id);
};

Dimension.contextType = TranslationContext;

export default Dimension;
