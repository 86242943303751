import React, { Component } from 'react';

import ElementSelectorSearch from './ElementSelectorSearch';
import ElementSelectorResults from './ElementSelectorResults';

import './ElementSelector.css';

class ElementSelector extends Component {
  constructor() {
    super();

    this.state = {
      queries: [],
      searching: false
    };
  }

  onSearch(queries) {
    this.setState({
      queries: queries,
      searching: true
    });
  }

  onResultsBack() {
    this.setState({ searching: false });
  }

  render() {
    return (
      <div className="ElementSelector">
        {this.screen()}
      </div>
    );
  }

  screen() {
    if (this.state.searching) {
      return (
        <ElementSelectorResults
          datasheets={this.props.datasheets}
          elementExpert={this.props.elementExpert}
          elementImages={this.props.elementImages}
          elementSelector={this.props.elementSelector}
          queries={this.state.queries}
          onBack={() => this.onResultsBack()}
          enquiries={this.props.enquiries}
        />
      );
    } else {
      return (
        <ElementSelectorSearch
          elementSelector={this.props.elementSelector}
          queries={this.state.queries}
          onSearch={(q) => this.onSearch(q)}
        />
      );
    }
  }
}

export default ElementSelector;
