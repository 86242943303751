import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './MenuItem.css';
import rightArrow from './right-arrow.svg';

class MenuItem extends Component {
  render() {
    return (
      <li className="MenuItem">
        {this.link()}
      </li>
    );
  }

  link() {
    if (this.props.href) {
      return (
        <a href={this.props.href} target="_blank" rel="noopener noreferrer">
          {this.inner()}
        </a>
      );
    } else {
      return (
        <Link to={this.props.to}>
          {this.inner()}
        </Link>
      );
    }
  }

  inner() {
    return (
      <div>
        <div className={'MenuItem-badge-count MenuItem-badge-count' + this.props.badgeCount}>
          {this.props.badgeCount}
        </div>
        {this.props.label}
        <img src={rightArrow} alt="" className="MenuItem-arrow"/>
      </div>
    );
  }
};

export default MenuItem;
