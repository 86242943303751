import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';

import BigHeader from '../components/BigHeader';
import ConditionalButton from '../components/ConditionalButton';
import DefaultButton from '../components/DefaultButton';
import EmptyPanel from '../components/panels/EmptyPanel';
import Error from '../components/Error';
import FormGroup from '../components/FormGroup';
import Intro from '../components/Intro';
import LabeledInputField from '../components/LabeledInputField';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import { TranslationContext } from '../translations/TranslationContext';

const background = require('./login-bg.jpg');

class SignIn extends Component {
  constructor() {
    super();

    this.state = {
      email: localStorage.getItem('email') || '',
      password: '',
      authenticationFailed: false,
      error: null
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  handleFieldChange(fieldId, value) {
    var nextState = Object.assign({}, this.state);
    nextState[fieldId] = value;
    this.setState(nextState);

    if (fieldId !== 'password') {
      localStorage.setItem(fieldId, value);
    }
  }

  canSignIn() {
    // Return true for now since browser auto-fill does not provide JS access to
    // username and password fields until the user interacts with the page
    // which means that the initial state will be disabled even after auto-fill.
    return true;
    return this.state.email.length > 2 && this.state.password.length > 0;
  }

  attemptSignIn() {
    const component = this;
    this.props.auth.authenticate(this.state.email, this.state.password).then(function(token) {
      component.props.onSignIn(token);
    }).catch(function(error) {
      component.setState({ authenticationFailed: true, error: error });
    });
  }

  t = (id) => this.context.translate('account.sign_in.' + id);

  render() {
    return (
      <div className="SignIn">
        <PrimaryPanel>
          <BigHeader/>
          <Intro>
            <p>
              {this.t('intro')}
            </p>
          </Intro>
          <FormGroup>
            <form onSubmit={(e) => { e.preventDefault(); this.attemptSignIn(); }}>
              <h2>{this.t('login')}</h2>
              <LabeledInputField
                label={this.t('email')}
                id="email"
                type="email"
                value={this.state.email}
                onChange={this.handleFieldChange}
              />
              <LabeledInputField
                label={this.t('password')}
                id="password"
                type="password"
                value={this.state.password}
                onChange={this.handleFieldChange}
              />
              <ConditionalButton
                text={this.t('login')}
                condition={() => this.canSignIn()}
                type="submit"
              />
              <p>
                <Link to="/account/reset-password">{this.t('reset_password')}</Link>
              </p>
              {this.authenticationError()}
            </form>
          </FormGroup>
          <br />
          <FormGroup>
            <h2>{this.t('not_got_a_login')}</h2>
            <Route render={({history}) => (
              <DefaultButton
                type='button'
                onClick={() => { history.push('/account/register') }}
                text={this.t('register')}/>
            )} />
          </FormGroup>
        </PrimaryPanel>
        <EmptyPanel background={background}>
        </EmptyPanel>
      </div>
    );
  }

  authenticationError() {
    if (!this.state.authenticationFailed) {
      return null;
    }

    var message;
    if (this.state.error === 'Pending') {
      message = this.t('pending');
    } else if (this.state.error === 'Unverified') {
      message = this.t('unverified');
    } else {
      message = this.t('authentication_failed');
    }

    return <Error>{message}</Error>;
  }
}

SignIn.contextType = TranslationContext;

export default SignIn;
