export default function cleanQuery(query) {
  let cleaned = '';
  let alphanumeric = /[0-9a-zA-Z]/;
  for (let i = 0; i < query.length; i++) {
    let char = query.charAt(i);
    if (char.match(alphanumeric)) {
      cleaned += char;
    }
  }
  return cleaned.toUpperCase();
}
