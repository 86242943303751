import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import BackButton from '../components/BackButton';
import EmptyPanel from '../components/panels/EmptyPanel';
import FormGroup from '../components/FormGroup';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import OEMAndModel from './OEMAndModel';
import OEMPartNumber from './OEMPartNumber';
import OEMResults from './OEMResults';
import './OEMApps.css';
import cleanQuery from '../CleanQuery';

import { TranslationContext } from '../translations/TranslationContext';

const background = require('./bg-oem.jpg');

class OEMApps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oemPartNumber: '',
      parfitPartNumber: '',
      results: null,
      searching: false
    };

    this.findProductsByOEMAndModel = this.findProductsByOEMAndModel.bind(this);
    this.findProductsByOEMPartNumber = this.findProductsByOEMPartNumber.bind(this);
    this.findProductsByParkerPartNumber = this.findProductsByParkerPartNumber.bind(this);
  }

  t = (id) => this.context.translate('oem_apps.oem_apps.' + id);

  findProductsByOEMAndModel(oemId, model) {
    let component = this;
    this.setState({ searching: true });

    this.props.repos.products.forOEMAndModel(oemId, model)
      .then(function(products) {
        component.setState({
          searching: false,  results: { data: products, visible: 15 }
        });
        return component.addDatasheetUrls();
      }).catch(error => console.error(error));
  }

  findProductsByOEMPartNumber(partNumber) {
    let component = this;
    this.setState({ searching: true });

    this.props.repos.products.forOEMPartNumber(cleanQuery(partNumber))
      .then(function(products) {
        component.setState({
          searching: false, results: { data: products, visible: 15 }
        });
        return component.addDatasheetUrls();
      }).catch(error => console.error(error));
  }

  findProductsByParkerPartNumber(partNumber) {
    let component = this;
    this.setState({ searching: true });

    this.props.repos.products.forParkerPartNumber(cleanQuery(partNumber))
      .then(function(products) {
        component.setState({
          searching: false, results: { data: products, visible: 15 }
        });
        return component.addDatasheetUrls();
      }).catch(error => console.error(error));
  }

  addDatasheetUrls() {
    return this.props.repos.datasheets.addUrls(this.state.results.data)
      .then(matchedData =>
        this.setState({
          results: Object.assign(this.state.results, { data: matchedData })
        })
      ).catch(error => console.error(error));
  }

  onResultsBack() {
    this.setState({ results: null });
  }

  loadMore() {
    this.setState({
      results: {
        data: this.state.results.data,
        visible: this.state.results.visible + 15
      }
    });
  }

  render() {
    if (this.state.results !== null) {
      return (
        <OEMResults
          results={this.state.results}
          onBack={() => this.onResultsBack()}
          onLoadMore={() => this.loadMore()}
          datasheets={this.props.repos.datasheets}
          elementExpert={this.props.repos.elementExpert}
          elementImages={this.props.repos.elementImages}
          enquiries={this.props.enquiries}
        />
      );
    } else {
      return (
        <div>
          <PrimaryPanel>
            <Route render={({history}) => (
              <BackButton onClick={() => { history.push('/') }}/>
            )} />
            <h1>{this.t('oem_apps')}</h1>
            <p>
              {this.t('intro')}
            </p>
            <FormGroup>
              <OEMAndModel
                repos={this.props.repos}
                onSearch={this.findProductsByOEMAndModel}
              />
            </FormGroup>
            <br />
            <FormGroup>
              <OEMPartNumber
                title={this.t('oem_part_number')}
                onSearch={this.findProductsByOEMPartNumber}
              />
            </FormGroup>
            <br />
            <FormGroup>
              <OEMPartNumber
                title={this.t('parker_part_number')}
                onSearch={this.findProductsByParkerPartNumber}
              />
            </FormGroup>
          </PrimaryPanel>
          <EmptyPanel background={background}/>
        </div>
      );
    }
  }
}

OEMApps.contextType = TranslationContext;

export default OEMApps;
