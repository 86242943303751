// A repository for style images backed by the web API.
class StyleImageRepository {
  constructor(api) {
    this.api = api;
    this.styleImages = null;
  }

  fetchStyleImages(id) {
    return this.fetchAllImages().then(images => {
      const ourImages = images.filter(i => i.id === parseInt(id));
      if (ourImages.length === 0) {
        console.warn('No style images found for id', id);
        return {};
      }
      return {
        imageUrl: ourImages[0].url,
        lineDrawingUrl: ourImages[1].url,
      };
    });
  }

  fetchAllImages() {
    if (this.styleImages) {
      return Promise.resolve(this.styleImages);
    } else {
      return this.api.fetchStyleImages().then(data => {
        this.styleImages = data.resources.map(r => ({
          id: this.extractId(r.path),
          url: this.api.siteBase + 'storage/uploads/' + r.path
        }));
        return this.styleImages;
      });
    }
  }

  extractId(path) {
    return parseInt(path.split('_')[0].replace('styles/', '').split('.')[0]);
  }
}

export default StyleImageRepository;
