import { tr, detr } from './Muddy';

export default class ElementSelectorRepositoryLocal {
  constructor(db) {
    this.db = db;
  }

  competitors = () => (
    this.db.then(db =>
      db.transaction(['competitors'], 'readonly').objectStore('competitors').getAll()
    )
  );

  search(queries) {
    let promises = [];
    for (let query of queries) {
      query = tr(query);
      let range = this.partNumberRange(query);
      promises.push(this.db.then(db =>
        db.transaction(['competitorParts'], 'readonly')
          .objectStore('competitorParts')
          .index('competitor_number_n')
          .getAll(range)
      ));
    }
    return Promise.all(promises).then(results => {
      let merged = [].concat.apply([], results);
      let filtered = [];
      for (let result of merged) {
        filtered[result.id] = result;
      }
      return this
        .deob(filtered)
        .sort((a, b) => a.competitor_number.localeCompare(b.competitor_number));
    });
  }

  deob(competitorParts) {
    let arr = [];
    for (let part of competitorParts) {
      if (!part) {
        continue;
      }
      let newPart = Object.assign({}, part);
      newPart.competitor_number = detr(newPart.competitor_number);
      newPart.parker_number = detr(newPart.parker_number);
      arr.push(newPart);
    }
    return arr;
  }

  // Returns an IDBKeyRange for searching for part numbers beginning with the
  // given prefix.
  partNumberRange(prefix) {
    let number = prefix.toUpperCase();
    return IDBKeyRange.bound(number, number + '\uffff', false, false);
  }
}
