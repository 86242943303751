import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import BackButton from '../components/BackButton';
import Dimensions from './Dimensions';
import FiltrationType from './FiltrationType';
import NarrowPanel from '../components/panels/NarrowPanel';
import ParFit from '../components/ParFit';
import WidePanel from '../components/panels/WidePanel';

import { TranslationContext } from '../translations/TranslationContext';

import './ElementExpert.css';

class ElementExpert extends Component {
  constructor() {
    super();

    this.state = {
      imagesLoaded: false,
      types: []
    };
  }

  t = (id) => this.context.translate('element_expert.element_expert.' + id);

  componentDidMount() {
    this.props.elementExpert.filtrationTypesAndStyles()
      .then(types => this.setState({types}));
    this.props.styleImages.fetchAllImages()
      .then(_ => this.setState({ imagesLoaded: true }));
  }

  render() {
    if (this.state.selectedStyleId) {
      return this.dimensions();
    } else {
      return this.elementExpert();
    }
  }

  dimensions() {
    return (
      <Dimensions
        id={this.state.selectedStyleId}
        datasheets={this.props.datasheets}
        elementExpert={this.props.elementExpert}
        elementImages={this.props.elementImages}
        styleImages={this.props.styleImages}
        typeImages={this.props.typeImages}
        enquiries={this.props.enquiries}
        onBack={() => this.setState({ selectedStyleId: null })}
        />
    );
  }

  elementExpert() {
    return(
      <div className="ElementExpert">
        <NarrowPanel>
          <Route render={({history}) => (
            <BackButton onClick={() => { history.push('/') }}/>
          )} />
          <ParFit/>
          <h1>{this.t('element_expert')}</h1>
          <div dangerouslySetInnerHTML={{__html: this.t('intro')}}></div>
        </NarrowPanel>
        <WidePanel>
          {this.filtrationTypes()}
        </WidePanel>
      </div>
    );
  }

  filtrationTypes() {
    let components = [];

    if (!this.state.imagesLoaded) {
      return components;
    }

    for (let type of this.state.types) {
      components.push(
        <FiltrationType
          key={type.name}
          name={type.name}
          styles={type.styles}
          styleImages={this.props.styleImages}
          onStyleSelected={(id) => this.onStyleSelected(id)}
        />
      );
    }
    return components;
  }

  onStyleSelected(styleId) {
    this.setState({ selectedStyleId: styleId });
  }
}

ElementExpert.contextType = TranslationContext;

export default ElementExpert;
