import React, { Component } from 'react';

import ReactTooltip from 'react-tooltip'

import { TranslationContext } from '../translations/TranslationContext';

import './TickCross.css';

class TickCross extends Component {
  constructor(props) {
    super(props);
    this.symbol = props.value ? '✔' : '✖';
  }

  t = (id) => this.context.translate('components.tick_cross.' + id);

  tip() {
    if (this.props.part.active) {
      if (this.props.part.stocked) {
        return this.t('active_stocked');
      } else {
        return this.t('active_non_stocked');
      }
    } else {
      return this.t('inactive');
    }
  }

  render() {
    return (
      <div className="TickCross" data-tip={this.tip()}>
        {this.symbol}
        <ReactTooltip className="TickCross-tooltip" place="bottom" type="dark" effect="solid"/>
      </div>
    );
  }
}

TickCross.contextType = TranslationContext;

export default TickCross;
