export function initialiseDatabase(idb) {
  return idb.open('Toolkit', 1, db => {
    console.log('Initialising IndexedDB');
    // We're installing or upgrading so create our object stores and
    // indexes.
    db.createObjectStore('oems', { keyPath: 'id' });

    let products = db.createObjectStore('products', { keyPath: 'id' });
    products.createIndex('oem_id', 'oem_id', { unique: false });
    products.createIndex('oem_number_n', 'oem_number_n', { unique: false });
    products.createIndex('parker_number_n', 'parker_number_n', { unique: false });

    db.createObjectStore('competitors', { keyPath: 'id' });

    let competitorParts = db.createObjectStore('competitorParts', { keyPath: 'id' });
    competitorParts.createIndex('competitor_number_n', 'competitor_number_n', { unique: false });

    let elements = db.createObjectStore('elements', { keyPath: 'id' });
    elements.createIndex('parker_number', 'parker_number', { unique: false });
    elements.createIndex('style_id', 'style_id', { unique: false });

    db.createObjectStore('filtrationTypes', { keyPath: 'id' });
    db.createObjectStore('styles', { keyPath: 'id' });

    let resources = db.createObjectStore('resources', { keyPath: 'path' });
    resources.createIndex('path', 'path', { unique: false });
  }).catch(error => {
    alert('Cannot initialise database (IndexedDB).');
  });
}
