import React, { Component } from 'react';

import DefaultButton from '../components/DefaultButton';
import FormGroup from '../components/FormGroup';
import './Notification.css';

import { TranslationContext } from '../translations/TranslationContext';

class Notification extends Component {
  render() {
    return(
      <div className="Notification">
        <FormGroup highlighted={!this.props.notification.read}>
          <div className="Notification-title">
            {this.t('subject')}: {this.props.notification.title}<br/>
            {this.t('date')}: {new Date(this.props.notification.created_at).toLocaleDateString()}
          </div>
          <div className="Notification-content"
            dangerouslySetInnerHTML={this.props.notification.htmlContent()}></div>
          {this.button()}
        </FormGroup>
      </div>
    );
  }

  t = (id) => this.context.translate('notifications.notification.' + id);

  button() {
    const notification = this.props.notification;
    if (notification.read) {
      return (
        <div style={{display:'none'}}>
          <DefaultButton
            text={this.t('mark_as_unread')}
            onClick={() => this.props.onUnread(notification)}
          />
        </div>
      );
    } else {
      return (
        <DefaultButton
          text={this.t('mark_as_read')}
          onClick={() => this.props.onRead(notification)}
        />
      );
    }
  }
}

Notification.contextType = TranslationContext;

export default Notification;
