// A repository for Element Selector backed by a the web API.
export default class ElementSelectorRepository {
  constructor(api) {
    this.api = api;
  }

  search(queries) {
    return this.api.elementSelectorSearch(queries)
      .then(data => data.competitor_parts);
  }
}
