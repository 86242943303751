// A repository for type images backed by the web API.
class TypeImageRepository {
  constructor(api) {
    this.api = api;
    this.typeImages = null;
  }

  fetchTypeImages(id) {
    return this.fetchAllImages().then(images => {
      const ourImages = images.filter(i => i.id === parseInt(id));
      if (ourImages.length === 0) {
        console.warn('No type images found for id', id);
        return {};
      }
      return ourImages;
    });
  }

  fetchAllImages() {
    if (this.typeImages) {
      return Promise.resolve(this.typeImages);
    } else {
      return this.api.fetchTypeImages().then(data => {
        this.typeImages = data.resources.map(r => ({
          id: this.extractId(r.path),
          url: this.api.siteBase + 'storage/uploads/' + r.path
        }));
        return this.typeImages;
      });
    }
  }

  extractId(path) {
    return parseInt(path.split('_')[0].replace('types/', '').split('.')[0]);
  }
}

export default TypeImageRepository;
