import React, { Component } from 'react';
import { TranslationContext } from '../translations/TranslationContext';

export default class NoResults extends Component {

  t = (id) => this.context.translate('element_expert.no_results.' + id);

  render() {
    return (
      <div className="NoResults">
        <h1>{this.t('no_results')}</h1>
      </div>
    );
  }
}

NoResults.contextType = TranslationContext;
