import React, { Component } from 'react';

import CannotFindRequest from './CannotFindRequest';

import Intro from '../components/Intro';
import ConditionalButton from '../components/ConditionalButton';
import DemandTypes, {demandTypes} from '../components/DemandTypes';
import EmptyPanel from '../components/panels/EmptyPanel';
import EnquirySent from '../components/EnquirySent';
import FormGroup from '../components/FormGroup';
import LabeledInputField from '../components/LabeledInputField';
import LabeledSelectField from '../components/LabeledSelectField';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import background from '../contact/contact-bg.jpg';
import validQuantity from '../ValidQuantity';
import './CannotFind.css';

import { TranslationContext } from '../translations/TranslationContext';

class CannotFind extends Component {
  constructor() {
    super();

    this.state = this.defaultState();

    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  t = (id) => this.context.translate('cannot_find.cannot_find.' + id);

  handleFieldChange(fieldId, value) {
    var nextState = Object.assign({}, this.state);
    nextState[fieldId] = value;
    this.setState(nextState);
  }

  handleFileUpload(event) {
    const component = this;
    const file = event.target.files[0];

    if (typeof(file) === 'undefined') {
      this.setState({ fileData: null, previewData: null, fileName: null });
      return;
    }

    var reader  = new FileReader();

    reader.addEventListener('load', function () {
      const previewData = reader.result;
      const fileParts = previewData.split(',');
      const fileInfo = fileParts[0];
      const fileData = fileParts[1];
      if (component.isImage(fileInfo)) {
        component.setState({ fileData, previewData });
      } else {
        component.setState({ fileData: null, previewData: null, fileName: null });
        this.fileInput.value = '';
      }
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }

    this.setState({ fileName: file.name });
  }

  canSend() {
    return validQuantity(this.state.quantity);
  }

  onSend() {
    this.props.backgroundQueue.enqueue(new CannotFindRequest(this.state));
    this.fileInput.value = '';
    this.setState(Object.assign({}, this.defaultState(), { sent: true }));
  }

  defaultState() {
    return {
      manufacturer: '',
      partNumber: '',
      filtrationType: '',
      filterDimensions: '',
      notes: '',
      quantity: '',
      demandType: demandTypes[0],
      contactAccepted: false,
      fileName: null,
      fileData: null,
      previewData: null
    };
  }

  render() {
    const filtrationTypes = [
      <option key="hydraulic" value="Hydraulic">Hydraulic</option>,
      <option key="fueloil" value="Fuel and Oil">Fuel and Oil</option>,
      <option key="air" value="Air">Air</option>,
      <option key="crankcasebreather" value="Crankcase Breather">Crankcase Breather</option>
    ];
    return(
      <div className="CannotFind">
        <PrimaryPanel>
          <h1>{this.t('title')}</h1>
          <Intro>
            {this.t('intro')}
          </Intro>
          <form onSubmit={(e) => { e.preventDefault(); this.onSend(); }}>
            <FormGroup>
              <h2>{this.t('unknown_product_enquiry')}</h2>
              <LabeledInputField
                label={this.t('manufacturer')}
                id="manufacturer"
                onChange={this.handleFieldChange}
                value={this.state.manufacturer}/>
              <LabeledInputField
                label={this.t('part_number')}
                id="partNumber"
                onChange={this.handleFieldChange}
                value={this.state.partNumber}/>
              <LabeledSelectField
                label={this.t('filtration_type')}
                id="filtrationType"
                children={filtrationTypes}
                onChange={this.handleFieldChange}
                value={this.state.filtrationType}/>
              <LabeledInputField
                label={this.t('filter_dimensions')}
                id="filterDimensions"
                onChange={this.handleFieldChange}
                value={this.state.filterDimensions}/>
              <LabeledInputField
                label={this.t('notes')}
                id="notes"
                onChange={this.handleFieldChange}
                value={this.state.notes}/>
              <LabeledInputField
                label={this.t('quantity')}
                id="quantity"
                onChange={this.handleFieldChange}
                value={this.state.quantity}/>
              <label className="LabeledInputField-label" htmlFor="demandType">{this.t('demand_type')}</label>
              <DemandTypes
                id="demandType"
                demandType={this.state.demandType}
                onChange={(e) => this.handleFieldChange('demandType', e.target.value)}
                />
              <div className="FormWell">
                <table>
                  <tbody>
                    <tr>
                      <td style={{paddingRight: '1rem'}}>
                        <div className="CannotFind-preview">
                          {this.previewImage()}
                        </div>
                      </td>
                      <td>
                        <label className="LabeledInputField-label" htmlFor="file">
                          {this.t('upload_an_image')}
                        </label>
                        <input
                          type="file"
                          onChange={(event) => this.handleFileUpload(event)}
                          ref={ref=> this.fileInput = ref}
                          />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>{this.t('upload_help')}</p>
              </div>
            </FormGroup>
            {this.sentMessage()}
            <ConditionalButton
              type="submit"
              text={this.t('send')}
              condition={() => this.canSend()}
            />
          </form>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  sentMessage() {
    if (this.state.sent) {
      return <EnquirySent queued={this.props.electron}/>;
    } else {
      return null;
    }
  }

  previewImage() {
    if (this.state.previewData) {
      return <img src={this.state.previewData} alt=""/>;
    }
    return null;
  }

  isImage(fileInfo) {
    return fileInfo.startsWith('data:image/jpeg') ||
      fileInfo.startsWith('data:image/png') ||
      fileInfo.startsWith('data:image/gif') ||
      fileInfo.startsWith('data:image/bmp');
  }
}

CannotFind.contextType = TranslationContext;

export default CannotFind;
