import React, { Component } from 'react';

import CannotFindLinkBlock from './CannotFindLinkBlock';
import { TranslationContext } from '../translations/TranslationContext';

class NoResults extends Component {
  t = (id) => this.context.translate('components.no_results.' + id);

  render() {
    return (
      <div className="NoResults">
        <p>
          {this.t('no_results')}
        </p>
        <CannotFindLinkBlock />
      </div>
    );
  }
}

NoResults.contextType = TranslationContext;

export default NoResults;
