import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './header-logo.svg';
import homeIcon from './home-icon.svg';
import languageIcon from './language-icon.svg';

import { TranslationContext } from '../../translations/TranslationContext';

class HeaderMinimal extends Component {
  t = (id) => this.context.translate('components.header.menu.' + id);

  render() {
    return (
      <header className="Header">
        <ul className="Header-menu">
          <li className="Header-rstripe">
            <Link to="/">
              <div className="Header-icon">
                <img className="Header-home-icon" alt="" src={homeIcon}/>
              </div>
              <span>{this.t('home')}</span>
            </Link>
          </li>
          <li className="Header-logo"><img src={logo} alt="ParFit Toolkit"/></li>
        </ul>
        <ul className="Header-menu HeaderRight">
          <li className="Header-language">
            <Link to="/language">
              <div className="Header-icon">
                <img className="Header-language-icon" alt="" src={languageIcon}/>
              </div>
              <span>{this.t('language')}</span>
            </Link>
          </li>
        </ul>
      </header>
    );
  }
}

HeaderMinimal.contextType = TranslationContext;

export default HeaderMinimal;
