import React, { Component } from 'react';

import './PlusMinus.css';
import './RemoveButton.css';

class RemoveButton extends Component {
  render() {
    return(
      <button
        type="button"
        className="RemoveButton"
        onClick={this.props.onRemove}>
        <div className="PlusMinus">-</div>
      </button>
    );
  }
}

export default RemoveButton;
