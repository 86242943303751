import React, { Component } from 'react';

import DefaultButton from './DefaultButton';

import { TranslationContext } from '../translations/TranslationContext';

class LoadMore extends Component {
  t = (id) => this.context.translate('components.load_more.' + id);

  render() {
    if (this.props.results.visible >= this.props.results.data.length) {
      return null;
    } else {
      return (
        <DefaultButton
          text={this.t('load_more')}
          onClick={() => this.props.onLoadMore()}
        />
      );
    }
  }
}

LoadMore.contextType = TranslationContext;

export default LoadMore;
