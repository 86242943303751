// A repository for Element Expert backed by a the web API.
export default class ElementExpertRepository {
  cachedFiltrationTypes = [];
  cachedStyles = [];

  constructor(api) {
    this.api = api;
  }

  filtrationTypesAndStyles() {
    const fetches = [this.fetchFiltrationTypes(), this.fetchStyles()];

    return Promise.all(fetches).then(results => {
      let [filtrationTypes, styles] = results;
      let types = [];
      for (let ft of filtrationTypes) {
        types.push({
          name: ft.name,
          styles: styles.filter(s => s.filtration_type_id === parseInt(ft.id))
        });
      }
      return types;
    });
  }

  fetchStyle(id) {
    return this.fetchStyles().then(styles => {
      return styles.filter(s => s.id === parseInt(id))[0];
    });
  }

  fetchFiltrationTypes() {
    if (this.cachedFiltrationTypes.length) {
      return Promise.resolve(this.cachedFiltrationTypes);
    } else {
      return this.api.fetchFiltrationTypes()
        .then(data => {
          this.cachedFiltrationTypes = data.filtration_types;
          return this.cachedFiltrationTypes;
        });
    }
  }

  fetchStyles() {
    if (this.cachedStyles.length) {
      return Promise.resolve(this.cachedStyles);
    } else {
      return this.api.fetchStyles()
        .then(data => {
          this.cachedStyles = data.styles;
          return this.cachedStyles;
        });
    }
  }

  search(styleId, dimensions, tolerances) {
    return this.api.elementExpertSearch(styleId, dimensions, tolerances)
      .then(data => data.elements);
  }

  fetchElement(parkerNumber) {
    return this.api.fetchElement(parkerNumber).then(data => data.element);
  }
}
