import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import EmptyPanel from '../components/panels/EmptyPanel';
import PrimaryPanel from '../components/panels/PrimaryPanel';
import { TranslationContext } from '../translations/TranslationContext';

import './Home.css';
import parfitToolkit from '../components/parfit-toolkit.svg';
import background from './background.jpg';
import go from '../product_info/image-next.svg';

class Home extends Component {
  t = (id) => this.context.translate('home.home.' + id);

  render() {
    return (
      <div className="Home">
        <PrimaryPanel>
          <img className="Home-parfit-toolkit" src={parfitToolkit} alt="Parfit Toolkit"/>
          <p>
            {this.t('intro')}
          </p>
          <Link className="Home-link" to="/element-selector">
            <h2>{this.t('element_selector_title')}</h2>
            <div className="Home-link-description">
              {this.t('element_selector_description')}
              <img src={go} alt=""/>
            </div>
          </Link>
          <Link className="Home-link" to="/element-expert">
            <h2>{this.t('element_expert_title')}</h2>
            <div className="Home-link-description">
              {this.t('element_expert_description')}
              <img src={go} alt=""/>
            </div>
          </Link>
          <Link className="Home-link" to="/oem-apps">
            <h2>{this.t('oem_apps_title')}</h2>
            <div className="Home-link-description">
              {this.t('oem_apps_description')}
              <img src={go} alt=""/>
            </div>
          </Link>
          <Link className="Home-link" to="/datasheets">
            <h2>{this.t('datasheets_title')}</h2>
            <div className="Home-link-description">
              {this.t('datasheets_description')}
              <img src={go} alt=""/>
            </div>
          </Link>
          <Link className="Home-link" to="/video-tutorial">
            <h2>{this.t('video_tutorial_title')}</h2>
            <div className="Home-link-description">
              {this.t('video_tutorial_description')}
              <img src={go} alt=""/>
            </div>
          </Link>
          <br/>
          <br/>
          <div style={{display: 'none'}}>
            <button onClick={() => this.signOut()}>Sign Out</button> &larr; this is for testing purposes only
          </div>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  signOut() {
    this.props.onSignOut();
  }
}

Home.contextType = TranslationContext;

export default Home;
