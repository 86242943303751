import React, { Component } from 'react';

import './BackButton.css';

import { TranslationContext } from '../translations/TranslationContext';

import backArrow from './back-arrow.svg';

class BackButton extends Component {
  t = (id) => this.context.translate('components.back_button.' + id);

  render() {
    return (
      <button className="BackButton" onClick={this.props.onClick}>
      <img src={backArrow} alt=""/>
        {this.t('back')}
      </button>
    );
  }
}

BackButton.contextType = TranslationContext;

export default BackButton;
