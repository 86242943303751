import React, { Component } from 'react';

import EmptyPanel from '../components/panels/EmptyPanel';
import OptionalUpdate from './OptionalUpdate';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import { TranslationContext } from '../translations/TranslationContext';

const background = require('../account/bg-register.jpg');

class Updates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updaters: null
    };
  }

  t = (id) => this.context.translate('updates.updates.' + id);

  componentDidMount() {
    this.props.updateHelper.updatersWithAvailableUpdates()
      .then(updaters => this.setState({ updaters }));
  }

  render() {
    return(
      <div className="Updates">
        <PrimaryPanel>
          <h1>{this.t('updates')}</h1>
          {this.updates()}
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  updates() {
    if (this.state.updaters === null) {
      return <p>{this.t('checking')}</p>;
    }
    if (this.state.updaters.length === 0) {
      return <p>{this.t('up_to_date')}</p>;
    }
    return this.state.updaters.map((updater) =>
      <OptionalUpdate key={updater.name} updater={updater}/>
    );
  }
}

Updates.contextType = TranslationContext;

export default Updates;
