// A repository for element images backed by the web API.
class ElementImageRepository {
  constructor(api) {
    this.api = api;
    this.elementImages = null;
  }

  fetchElementImages(parkerNumber) {
    return this.fetchAllImages().then(images => {
      console.log('All element images', images);
      const ourImages = images.filter(i => i.parkerNumber === parkerNumber);
      if (ourImages.length === 0) {
        console.warn('No element images found for parkerNumber', parkerNumber);
      }
      return ourImages;
    });
  }

  fetchAllImages() {
    if (this.elementImages) {
      return Promise.resolve(this.elementImages);
    } else {
      return this.api.fetchElementImages().then(data => {
        this.elementImages = data.resources.map(r => ({
          parkerNumber: this.extractParkerNumber(r.path),
          url: this.api.siteBase + 'storage/uploads/' + r.path
        }));
        return this.elementImages;
      });
    }
  }

  extractParkerNumber(path) {
    return path.split('_')[0].replace('elements/', '').split('.')[0];
  }
}

export default ElementImageRepository;
