import React, { Component } from 'react';
import './DefaultButton.css';

class DefaultButton extends Component {
  render() {
    return (
      <button className="DefaultButton" onClick={this.props.onClick} type={this.props.type || "button"}>
        {this.props.text}
      </button>
    );
  }
}

export default DefaultButton;
