import CompetitorUpdater from './CompetitorUpdater';
import CompetitorPartUpdater from './CompetitorPartUpdater';
import ElementUpdater from './ElementUpdater';
import FiltrationTypeUpdater from './FiltrationTypeUpdater';
import OEMUpdater from './OEMUpdater';
import ProductUpdater from './ProductUpdater';
import ResourceUpdater from './ResourceUpdater';
import StyleUpdater from './StyleUpdater';

export default class UpdateHelper {
  checkInterval = 15 * 1000;
  withUpdates = [];

  constructor(api, db, repos, resourceDownloader) {
    this.api = api;
    this.db = db;
    this.repos = repos;
    this.resourceDownloader = resourceDownloader;

    this.lastCheck = Date.now() - this.checkInterval;
  }

  updaters() {
    if (!this.updaters.updaters) {
      this.updaters.updaters = [
        new OEMUpdater(this.api, this.db, this.repos),
        new ProductUpdater(this.api, this.db, this.repos),
        new CompetitorUpdater(this.api, this.db, this.repos),
        new CompetitorPartUpdater(this.api, this.db, this.repos),
        new ElementUpdater(this.api, this.db, this.repos),
        new FiltrationTypeUpdater(this.api, this.db, this.repos),
        new StyleUpdater(this.api, this.db, this.repos),
        new ResourceUpdater(this.api, this.db, this.repos, this.resourceDownloader, 'Datasheets', 'datasheets'),
        new ResourceUpdater(this.api, this.db, this.repos, this.resourceDownloader, 'Element images', 'elements'),
        new ResourceUpdater(this.api, this.db, this.repos, this.resourceDownloader, 'Style images', 'styles'),
        new ResourceUpdater(this.api, this.db, this.repos, this.resourceDownloader, 'Type images', 'types'),
      ];
    }
    return this.updaters.updaters;
  }

  updatersWithAvailableUpdates() {
    if (this.lastCheck > Date.now() - this.checkInterval) {
      return Promise.resolve(this.withUpdates);
    }

    return Promise.all(
      this.updaters().map(updater => updater.updateAvailable())
    ).then(hasUpdates => {
      let updaters = this.updaters();
      this.withUpdates = [];
      for (let i = 0; i < hasUpdates.length; i++) {
        if (hasUpdates[i]) {
          this.withUpdates.push(updaters[i]);
        }
      }
      return Promise.resolve(this.withUpdates)
    });
  }

  updateCount() {
    return this.updatersWithAvailableUpdates()
      .then(updaters => Promise.resolve(updaters.length));
  }
}
