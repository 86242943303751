import React, { Component } from 'react';

import './Style.css';

export default class StyleImage extends Component {
  constructor() {
    super();
    this.state = {
      imageUrl: null
    };
  }

  componentDidMount() {
    this.props.styleImages.fetchStyleImages(this.props.id).then(images => {
      this.setState({ imageUrl: images.imageUrl });
    });
  }

  render() {
    if (this.state.imageUrl === null) {
      return null;
    }
    return (
      <img src={this.state.imageUrl} alt="" />
    );
  }
};
