import React, { Component } from 'react';

import ProductStatus from './ProductStatus';
import './ProductInfo.css';

import { TranslationContext } from '../translations/TranslationContext';

class HifeProductInfo extends Component {

  t = (id) => this.context.translate('product_info.hife_product_info.' + id);

  render() {
    return (
      <div className="HifeProductInfo">
        <h1 className="ProductInfo-parker-number">{this.props.element.parker_number}</h1>
  
        <ProductStatus element={this.props.element}/>
  
        <h2>{this.t('product_features')}</h2>
        <table className="ProductInfo-table">
          <tbody>
            <tr>
              <th>{this.t('seals')}</th>
              <td>{this.props.element.seals}</td>
            </tr>
            <tr>
              <th>{this.t('media_type')}</th>
              <td>{this.props.element.media_type}</td>
            </tr>
            <tr>
              <th>{this.t('top_endcap')}</th>
              <td>{this.props.element.top_endcap}</td>
            </tr>
            <tr>
              <th>{this.t('bottom_endcap')}</th>
              <td>{this.props.element.bottom_endcap}</td>
            </tr>
            <tr>
              <th>{this.t('media')}</th>
              <td>{this.props.element.media}</td>
            </tr>
            <tr>
              <th>{this.t('inner_support_cylinder')}</th>
              <td>{this.props.element.inner_support_cylinder}</td>
            </tr>
            <tr>
              <th>{this.t('efficiency')}</th>
              <td>
                &gt;Beta200 @ {this.props.element.efficiency_200} microns &amp; {this.t('greater')},
                &gt;Beta1000 @ {this.props.element.efficiency_1000} microns &amp; {this.t('greater')}
              </td>
            </tr>
            <tr>
              <th>Dp [Bar]</th>
              <td>
                {this.props.element.differential_pressure} bar @ {this.props.element.diff_flow_lpm} l/pm
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

HifeProductInfo.contextType = TranslationContext;

export default HifeProductInfo;
