import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ViewButton.css';
import magnify from './magnify.svg';

import { TranslationContext } from '../translations/TranslationContext';

class ViewImageButton extends Component {

  t = (id) => this.context.translate('components.view_image_button.' + id);

  render() {
    return(
      <button className="ViewButton DefaultButton" onClick={this.props.onClick}>
        {this.t('view')}&nbsp;<img src={magnify} alt=""/>
      </button>
    );
  }
}

ViewImageButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

ViewImageButton.contextType = TranslationContext;

export default ViewImageButton;

