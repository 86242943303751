class BackgroundQueue {
  /**
   * @param {API} api
   * @param {Object} storage - providing getItem and setItem compatible with localStorage
   * @param {number} interval - time in seconds between queue processing
   */
  constructor(api, storage, interval) {
    this.queueKey = 'backgroundQueue';
    this.api = api;
    this.storage = storage;
    this.load();

    interval = parseInt(interval, 10);
    if (isNaN(interval) || interval < 5) {
      throw new Error('interval is not a number greater than 5');
    }

    this.process = this.process.bind(this);
    setInterval(this.process, interval * 1000);
  }

  // Adds a new request to the queue.
  enqueue(request) {
    this.queue.push(request);
    this.persist();
  }

  // Processes the oldest request in the queue if the server is reachable. If
  // the request fails the request is added back to the end of the queue.
  process() {
    if (!this.api.hasToken()) {
      console.log('skipping queue processing as not authenticated');
      return;
    }
    console.log('processing queue...');
    const bq = this;
    // Return if queue is empty; no point checking for reachability.
    if (bq.queue.length === 0) {
      return;
    }

    // Check for reachability...
    bq.api.ping().then(function() {
      // Ensure queue is still non-empty after network call.
      if (bq.queue.length === 0) {
        return;
      }
      let request = bq.queue.shift();
      bq.persist();
      bq.api.post(request.path, request.data).catch(function(errorJSON) {
        console.error(errorJSON);
        bq.enqueue(request);
      });
    });
  }

  // Removes all requests from the queue.
  purge() {
    this.queue = [];
    this.persist();
  }

  // Writes the queue data to storage.
  persist() {
    this.storage.setItem(this.queueKey, JSON.stringify(this.queue));
  }

  // Loads the queue data from storage.
  load() {
    this.queue = JSON.parse(this.storage.getItem(this.queueKey) || '[]');
    console.log('loaded queue', this.queue);
  }
}

export default BackgroundQueue;
