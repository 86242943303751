import React from 'react';

import EmptyPanel from '../components/panels/EmptyPanel';
import PrimaryPanel from '../components/panels/PrimaryPanel';

const background = require('./information-bg.jpg');

export default function Information() {
  return (
    <div className="Information">
      <PrimaryPanel>
        <h1>Information</h1>
        <p>
          <strong>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab
          illo inventore veritatis et quasi architecto beatae vitae dicta sunt,
          explicabo.
          </strong>
        </p>
        <p>
          Donec maximus mauris a euismod hendrerit. Mauris blandit, nibh vitae
          rhoncus maximus, purus tortor euismod magna, non suscipit libero ex
          vitae sapien. Nulla hendrerit sapien sed nisl commodo, sed condimentum
          augue venenatis. In eget orci fringilla, gravida urna quis, ultrices
          turpis. Vivamus non enim non diam dapibus volutpat vel vehicula dolor.
          Aenean sit amet risus ut enim ullamcorper sagittis a id quam. Sed
          auctor, nisl nec lobortis tristique, augue lorem vulputate lacus, quis
          vehicula neque felis ac orci. Donec dolor odio, ultricies nec est at,
          consequat fermentum augue. Phasellus placerat tincidunt sem, quis auctor
          velit pellentesque et. Etiam convallis aliquam accumsan. Fusce efficitur
          lectus tellus, a euismod leo vestibulum id. Nam elit lectus, luctus non
          mi ac, suscipit molestie eros.
        </p>
        <h2>Header Style</h2>
        <ul>
          <li>Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas.</li>
          <li>Ut vitae sem rutrum, iaculis dui quis, elementum dolor.</li>
          <li>Proin magna turpis, dapibus non dignissim at, tincidunt quis felis.</li>
        </ul>
        <p>
          Nulla dui lorem, suscipit non elit a, pellentesque commodo nunc. Nam
          tempor augue eros, vitae finibus justo venenatis nec. Maecenas vel
          accumsan urna, eu dictum purus. Morbi rhoncus rutrum mauris, eget
          mattis tortor finibus sit amet. Pellentesque vel sapien at arcu cursus
          commodo vitae nec ante.
        </p>
        <p>
          Vestibulum eget erat auctor orci tincidunt ultrices. Donec vel metus
          eu erat aliquet cursus. Praesent quis sapien non quam iaculis iaculis
          in in lorem. Phasellus sit amet ex aliquam, vehicula massa et,
          eleifend lacus. Sed non condimentum tortor. Maecenas neque eros,
          dictum a ligula vel, efficitur egestas sapien. Sed elementum ipsum sit
          amet nulla scelerisque, maximus semper tellus blandit.
        </p>
      </PrimaryPanel>
      <EmptyPanel background={background}>
      </EmptyPanel>
    </div>
  );
}
