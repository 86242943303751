import React, { Component } from 'react';

import AddEnquiryButton from '../components/AddEnquiryButton';
import BackButton from '../components/BackButton';
import CannotFindLinkBlock from '../components/CannotFindLinkBlock';
import FullPanel from '../components/FullPanel';
import LoadMore from '../components/LoadMore';
import MediaInfo from '../media_info/MediaInfo';
import ProductImage from './ProductImage';
import ProductInfo from '../product_info/ProductInfo';
import NoResults from './NoResults';
import ResultsHeading from '../components/ResultsHeading';
import TickCross from '../components/TickCross';
import TypeViewer from '../type_viewer/TypeViewer';
import ViewDatasheetButton from '../components/ViewDatasheetButton';
import ViewInfoButton from '../components/ViewInfoButton';
import ViewImageButton from '../components/ViewImageButton';
import ViewMediaInfoButton from '../components/ViewMediaInfoButton';
import ViewTypeButton from '../components/ViewTypeButton';
import { TranslationContext } from '../translations/TranslationContext';

import promiseSerial from '../PromiseSerial';

class ElementExpertResults extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      elementType: null,
      images: {},
      mediaInfo: null,
      productInfo: null
    };

    if (props.results.data.length > 0) {
      const firstElement = props.results.data[0];
      this.hydraulic = firstElement.division_name === 'Hydraulic';
    }
  }

  t = (id) => this.context.translate('element_expert.element_expert_results.' + id);

  componentDidMount() {
    this.loadImages();
  }

  loadImages() {
    const component = this;

    let fetches = [];

    // We fetch images serially. This is because the first request will cause
    // a network or database hit before caching for subsequent requests. If
    // we requested all images in parallel, we'd make the same initial request
    // multiple times.
    for (let i = 0; i < this.props.results.data.length; i++) {
      const element = this.props.results.data[i];
      fetches.push(() =>
        this.props.elementImages.fetchElementImages(element.parker_number)
          .then(images => {
            if (images.length > 0) {
              let elementImages = Object.assign({}, component.state.images);
              elementImages[element.parker_number] = images[0].url;
              component.setState({ images: elementImages });
            }
          })
      );
    }
    promiseSerial(fetches);
  }


  render() {
    if (this.state.productInfo) {
      return this.productInfo();
    } else if (this.state.mediaInfo) {
      return this.mediaInfo();
    } else {
      return this.elementExpertResults();
    }
  }

  mediaInfo() {
    return (
      <MediaInfo
        onBack={() => this.setState({ mediaInfo: null })}
        parkerNumber={this.state.mediaInfo}
        elementExpert={this.props.elementExpert}
        />
    );
  }

  productInfo() {
    return (
      <ProductInfo
        onBack={() => this.setState({ productInfo: null })}
        parkerNumber={this.state.productInfo}
        datasheets={this.props.datasheets}
        elementExpert={this.props.elementExpert}
        elementImages={this.props.elementImages}
        enquiries={this.props.enquiries}
        />
    );
  }

  typeViewer() {
    if (this.state.elementType) {
      return (
        <TypeViewer
          onClose={() => this.setState({ elementType: null })}
          id={this.state.elementType}
          typeImages={this.props.typeImages}
          />
      );
    } else {
      return null;
    }
  }

  elementExpertResults() {
    return (
      <FullPanel className="ElementExpertResults">
        <BackButton onClick={this.props.onBack} />
        {this.results()}
        <CannotFindLinkBlock />
      </FullPanel>
    );
  }

  results() {
    if (this.props.results.data.length === 0) {
      return <NoResults/>;
    } else {
      return this.resultsTable();
    }
  }

  resultsTable() {
    let table = [];
    for (let i in this.props.results.data) {
      let element = this.props.results.data[i];
      if (table.length >= this.props.results.visible) {
        break;
      }
      table.push(
        <tr key={element.id}>
          <td>
            {element.parker_number}
          </td>
          {this.imageColumn(element)}
          {this.typeColumn(element)}
          {this.mediaInfoColumn(element)}
          <td>
            {this.micronRating(element)}
          </td>
          <td>
            <TickCross value={element.active} part={element}/>
          </td>
          <td>
            <TickCross value={element.stocked} part={element}/>
          </td>
          <td>
            <ViewInfoButton
              onClick={(parkerNumber) => this.onProductInfo(parkerNumber)}
              parkerNumber={element.parker_number}
              />
          </td>
          <td>
            <ViewDatasheetButton url={element.datasheetUrl}/>
          </td>
          <td>
            <AddEnquiryButton
              enquiries={this.props.enquiries}
              parkerNumber={element.parker_number}
            />
          </td>
        </tr>
      );
    }
    return (
      <div className="ElementExpertResults">
        <ResultsHeading>{this.t('element_expert_results')}</ResultsHeading>
        {this.productImage()}
        {this.typeViewer()}
        <div className="Results-container">
          <table className="Results Results-desktop">
            <thead>
              <tr>
                <th>{this.t('part_number')}<span className="Results-sliver"></span></th>
                {this.imageColumnHeader()}
                {this.typeColumnHeader()}
                {this.mediaInfoColumnHeader()}
                <th>{this.t('micron_rating')}<span className="Results-sliver"></span></th>
                <th>{this.t('active')}<span className="Results-sliver"></span></th>
                <th>{this.t('stocked')}<span className="Results-sliver"></span></th>
                <th>{this.t('more_info')}<span className="Results-sliver"></span></th>
                <th>{this.t('datasheet')}<span className="Results-sliver"></span></th>
                <th>{this.t('enquire')}<span className="Results-sliver"></span></th>
              </tr>
            </thead>
            <tbody>
              {table}
            </tbody>
          </table>
        </div>
        <LoadMore
          results={this.props.results}
          onLoadMore={() => this.props.onLoadMore()}
        />
      </div>
    );
  }

  productImage() {
    if (this.state.image) {
      return (
        <ProductImage
          image={this.state.image}
          onClose={() => this.setState({ image: null })}
          />
      );
    }
    return null;
  }

  imageColumn(element) {
    const image = this.state.images[element.parker_number];
    if (this.hydraulic) {
      return null;
    } else if (image) {
      return (
        <td>
          <ViewImageButton onClick={() => this.setState({image})} />
        </td>
      );
    } else {
      return <td></td>;
    }
  }

  imageColumnHeader() {
    if (this.hydraulic) {
      return null;
    } else {
      return <th>{this.t('image')}<span className="Results-sliver"></span></th>;
    }
  }

  mediaInfoColumn(element) {
    if (this.hydraulic) {
      return (
        <td>
          <ViewMediaInfoButton
            onClick={(parkerNumber) => this.onMediaInfo(parkerNumber)}
            parkerNumber={element.parker_number}/>
        </td>
      );
    } else {
      return null;
    }
  }

  mediaInfoColumnHeader() {
    if (this.hydraulic) {
      return (
        <th>{this.t('media_info')}<span className="Results-sliver"></span></th>
      );
    }
  }

  typeColumn(element) {
    if (this.hydraulic) {
      return (
        <td>
          <ViewTypeButton
            onClick={(elementType) => this.onElementType(elementType)}
            elementType={element.element_type}
            />
        </td>
      );
    } else {
      return null;
    }
  }

  typeColumnHeader() {
    if (this.hydraulic) {
      return (
        <th>{this.t('type')}<span className="Results-sliver"></span></th>
      );
    }
  }

  micronRating(element) {
    if (element.media) {
      return element.media;
    } else {
      return this.t('please_enquire');
    }
  }

  onProductInfo(parkerNumber) {
    this.setState({ productInfo: parkerNumber });
  }

  onMediaInfo(parkerNumber) {
    this.setState({ mediaInfo: parkerNumber });
  }

  onElementType(elementType) {
    this.setState({ elementType: elementType });
  }
}

ElementExpertResults.contextType = TranslationContext;

export default ElementExpertResults;
