// A repository for datasheets backed by a the web API.
class DatasheetRepository {
  constructor(api) {
    this.api = api;
    this.datasheets = null;
  }

  matching(substring) {
    if (substring.length < 3) {
      return Promise.resolve([]);
    }

    return this.fetchDatasheets().then(function(datasheets) {
      let upCased = substring.toUpperCase();
      return datasheets
        .filter(d => d.partNumber.toUpperCase().indexOf(upCased) !== -1)
        .slice(0, 5);
    });
  }

  // Adds a datasheetUrl property to each object in parts whose parker_number
  // property matches datasheet partNumber.
  addUrls(parts) {
    return this.fetchDatasheets().then(function(datasheets) {
      // TODO: DRY (repeated in DatasheetRepositoryLocal).
      // TODO: Key datasheets by partNumber for faster lookup than O(N2).
      return parts.map(function(p) {
        let datasheet = datasheets.filter(d => d.partNumber.toUpperCase() === p.parker_number)[0];
        if (datasheet) {
          let newPart = Object.assign({}, p);
          newPart.datasheetUrl = datasheet.url;
          return newPart;
        } else {
          return p;
        }
      });
    });
  }

  fetchDatasheets() {
    let component = this;
    if (this.datasheets) {
      return Promise.resolve(this.datasheets);
    } else {
      return this.api.fetchDatasheets().then(function(data) {
        component.datasheets = data.resources.map(r => ({
          modified: r.modified,
          path: r.path,
          partNumber: r.path.toUpperCase().replace('DATASHEETS/', '').replace('.PDF', ''),
          url: component.api.siteBase + 'storage/uploads/' + r.path
        }));
        return component.datasheets;
      });
    }
  }
}

export default DatasheetRepository;
