import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import BackButton from '../components/BackButton';
import FullPanel from '../components/FullPanel';
import Intro from '../components/Intro';
import { TranslationContext } from '../translations/TranslationContext';

import './VideoTutorial.css';

class VideoTutorial extends Component {
  t = (id) => this.context.translate('video_tutorial.video_tutorial.' + id);

  render() {
    return (
      <FullPanel>
        <Route render={({history}) => (
          <BackButton onClick={() => { history.push('/') }}/>
        )} />
        <h1>{this.t('video_tutorial')}</h1>
        <Intro>
          {this.t('intro')}
        </Intro>
        <div className="VideoTutorial-layout">
          <div className="VideoTutorial-wrapper">
            <iframe
              src="https://player.vimeo.com/video/327971400"
              frameborder="0"
              title="Video tutorial"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen></iframe>
          </div>
        </div>
      </FullPanel>
    );
  }
}

VideoTutorial.contextType = TranslationContext;

export default VideoTutorial;
