import React from 'react';
import PropTypes from 'prop-types';

import './ProductImage.css';

const ProductImage = (props, context) => {
  return (
    <div className="ProductImage">
      <button onClick={props.onClose}>Close</button>
      <div className="ProductImage-image-container" style={{backgroundImage: `url(${props.image})`}}>
      </div>
    </div>
  );
}

ProductImage.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ProductImage;
