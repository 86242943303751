/**
 * Provides storage and retrieval for the currently signed in engineer's
 * JWT token.
 */
class EngineerRepository {
  constructor(localStorage) {
    this.localStorage = localStorage;
  }
  /**
   * Fetches the current JWT from storage. Returns null if no JWT found.
   */
  getToken() {
    let token = this.localStorage.getItem('token');
    if (token) {
      return JSON.parse(token);
    } else {
      return null;
    }
  }

  setToken(token) {
    if (token) {
      this.localStorage.setItem('token', JSON.stringify(token));
    } else {
      this.localStorage.removeItem('token');
    }
  }
}

export default EngineerRepository;
