import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import CheckEmail from './CheckEmail';

import BigHeader from '../components/BigHeader';
import ConditionalButton from '../components/ConditionalButton';
import CountrySelectField from '../components/CountrySelectField';
import EmptyPanel from '../components/panels/EmptyPanel';
import Intro from '../components/Intro';
import LabeledInputField from '../components/LabeledInputField';
import PrimaryPanel from '../components/panels/PrimaryPanel';
import RadioButton from '../components/RadioButton';
import './Register.css';

import { TranslationContext } from '../translations/TranslationContext';

const background = require('./bg-register.jpg');

class Register extends Component {
  constructor() {
    super();

    this.state = {
      redirectToLoginOrReset: false,
      firstName: localStorage.getItem('firstName') || '',
      surname: localStorage.getItem('surname') || '',
      company: localStorage.getItem('company') || '',
      address: localStorage.getItem('address') || '',
      city: localStorage.getItem('city') || '',
      postcode: localStorage.getItem('postcode') || '',
      country: localStorage.getItem('country') || '',
      interest: localStorage.getItem('interest') || '',
      email: localStorage.getItem('email') || '',
      password: localStorage.getItem('password') || ''
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onRegistrationFail = this.onRegistrationFail.bind(this);
    this.onRegistrationResponse = this.onRegistrationResponse.bind(this);
  }

  t = (id) => this.context.translate('account.register.' + id);

  handleFieldChange(fieldId, value) {
    var nextState = Object.assign({}, this.state);
    nextState[fieldId] = value;
    this.setState(nextState);

    localStorage.setItem(fieldId, value);
  }

  canRegister() {
    return (
      this.state.firstName &&
      this.state.surname &&
      this.state.company &&
      this.state.address &&
      this.state.city &&
      this.state.country &&
      this.state.interest &&
      this.state.email &&
      this.state.password && this.state.password.length >= 6
    );
  }

  onRegister() {
    let component = this;
    component.setState({ loading: true });
    this.props.api.register({
      city: this.state.city,
      company: this.state.company,
      country: this.state.country,
      email: this.state.email,
      first_name: this.state.firstName,
      interest: this.state.interest,
      password: this.state.password,
      postcode: this.state.postcode,
      street: this.state.address,
      surname: this.state.surname
    }).then(
      component.onRegistrationResponse,
      component.onRegistrationFail
    );
  }

  onRegistrationResponse(response) {
    this.setState({ loading: false, message: response.message });
  }

  onRegistrationFail(response) {
    this.setState({ loading: false, message: response.message });
  }

  render() {
    const { message } = this.state;

    switch (message) {
      case 'exists':
        return <Redirect to="/account/sign-in-or-reset"/>;
      case 'approved':
      case 'pending':
      case 'rejected':
        return <CheckEmail message={message}/>;
      default:
        return this.registrationForm();
    }
  }

  registrationForm() {
    return (
      <div className="Register">
        <PrimaryPanel>
          <BigHeader/>
          <Intro>
            <p>
              {this.t('intro')}
            </p>
          </Intro>
          <form onSubmit={(e) => { e.preventDefault(); this.onRegister(); }}>
            <LabeledInputField
              label={this.t('first_name')}
              id="firstName"
              onChange={this.handleFieldChange}
              value={this.state.firstName}/>
            <LabeledInputField
              label={this.t('surname')}
              id="surname"
              onChange={this.handleFieldChange}
              value={this.state.surname}/>
            <LabeledInputField
              label={this.t('company')}
              id="company"
              onChange={this.handleFieldChange}
              value={this.state.company}/>
            <LabeledInputField
              label={this.t('address')}
              id="address"
              onChange={this.handleFieldChange}
              value={this.state.address}/>
            <LabeledInputField
              label={this.t('city')}
              id="city"
              onChange={this.handleFieldChange}
              value={this.state.city}/>
            <LabeledInputField
              label={this.t('postcode')}
              id="postcode"
              onChange={this.handleFieldChange}
              value={this.state.postcode}/>
            <CountrySelectField
              onChange={this.handleFieldChange}
              value={this.state.country}
            />
            <label className="LabeledInputField-label">{this.t('interest')}</label>
            <RadioButton
              label={this.t('hydraulic_filtration')}
              selected={this.state.interest === 'Hydraulic'}
              onSelect={() => this.handleFieldChange('interest', 'Hydraulic')}
            />
            <RadioButton
              label={this.t('engine_filtration')}
              selected={this.state.interest === 'Engine Filtration'}
              onSelect={() => this.handleFieldChange('interest', 'Engine Filtration')}
            />
            <LabeledInputField
              label={this.t('email')}
              type="email"
              id="email"
              onChange={this.handleFieldChange}
              value={this.state.email}/>
            <LabeledInputField
              label={this.t('password')}
              type="password"
              id="password"
              onChange={this.handleFieldChange}
              value={this.state.password}/>
            <ConditionalButton
              type="submit"
              text={this.t('register')}
              loading={this.state.loading}
              condition={() => this.canRegister()}
              />
          </form>
          <p>
            <a href="http://www.parker.com/portal/site/PARKER/menuitem.4450f18f18c082cdfd40eae8237ad1ca/?vgnextoid=760b904cf58b2110VgnVCM100000c9040d0aRCRD&amp;vgnextfmt=EN"
            rel="noopener noreferrer"
            target="_blank">{this.t('privacy_policy')}</a>
          </p>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }
}

Register.contextType = TranslationContext;

export default Register;
