import React, { Component } from 'react';

import ConditionalButton from '../components/ConditionalButton';
import EmptyPanel from '../components/panels/EmptyPanel';
import Intro from '../components/Intro';
import LabeledInputField from '../components/LabeledInputField';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import { TranslationContext } from '../translations/TranslationContext';

const background = require('./bg-register.jpg');

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: localStorage.getItem('email') || '',
      message: ''
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  t = (id) => this.context.translate('account.reset_password.' + id);

  handleEmailChange(_id, email) {
    this.setState({ email: email });
  }

  canSend() {
    return this.state.email;
  }

  sendInstructions() {
    const component = this;
    this.setState({ loading: true, message: '' });
    this.props.api.resetPassword(this.state.email).then(function() {
      console.log('in good');
      component.setState({
        loading: false,
        message: component.t('reset_success')
      });
    }).catch(function() {
      console.log('in bad');
      component.setState({
        loading: false,
        message: component.t('reset_failure')
      });
    });
  }

  render() {
    return (
      <div className="ResetPassword">
        <PrimaryPanel>
          <h1>{this.t('reset_password')}</h1>
          <Intro>
            {this.t('intro')}
          </Intro>
          <p><strong>{this.state.message}</strong></p>
          <form onSubmit={(e) => { e.preventDefault(); this.sendInstructions(); }}>
            <LabeledInputField
              id="email"
              value={this.state.email}
              type="email"
              onChange={this.handleEmailChange}
            />
            <ConditionalButton
              type="submit"
              condition={() => this.canSend()}
              loading={this.state.loading}
              text={this.t('send_instructions')}
            />
          </form>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }
}

ResetPassword.contextType = TranslationContext;

export default ResetPassword;
