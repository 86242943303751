import React, { Component } from 'react';

import FullPanel from '../components/FullPanel';
import '../element_expert/ProductImage.css';

export default class TypeViewer extends Component {
  constructor() {
    super();
    this.state = {
      initialised: false,
      typeImages: null
    }
  }

  componentDidMount() {
    console.log(this.props);
    const id = this.props.id || this.props.match.params.id;
    this.props.typeImages.fetchTypeImages(id)
      .then(typeImages => this.setState({typeImages}));
  }

  componentDidUpdate() {
    this.initFWD();
  }

  initFWD() {
    if (this.state.typeImages === null || this.state.initialised) {
      return;
    }
    this.setState({ initialised: true });

		new window.FWDViewer({
			//----main----//
			divHolderId:"myDiv",
			skinPath:"skins/skin_minimal_dark",
			playListId:"viewerPlaylist",
			displayType:"responsive",
			preloaderText:"Loading",
			startDraggingMode:"rotate",
			showLargeImageVersionOnZoom:"no",
			useEntireScreenFor3dObject:"no",
			stopRotationHorizontalAtEnds:"no",
			stopRotationVerticalAtEnds:"no",
			addCorrectionForWebKit:"yes",
			addDragAndSpinSupport:"yes",
			disableMouseWheelZoom:"no",
			autoScale:"yes",
			startAtImage:0,
			startAtSet:0,
			viewerWidth:550,
			viewerHeight:550,
			imageWidth:800,
			imageHeight:801,
			zoomFactor:2,
			zoomSpeed:0.1,
			dragRotationSpeed:.7,
			dragAndSpinSpeed:.6,
			buttonsRotationSpeed:300,
			slideShowDelay:300,
			backgroundColor:"#FFFFFF",
			preloaderFontColor:"#666666",
			preloaderBackgroundColor:"#FFFFFF",
			//----lightbox-----//
			lightBoxWidth:800,
			lightBoxHeight:550,
			lightBoxBackgroundOpacity:.8,
			lightBoxBackgroundColor:"#000000",
			//----controller----//
			buttons:"rotate, pan, roteteleft, rotateright, scrollbar",
			buttonsToolTips:"",
			controllerHorizontalPosition:"center",
			controllerVerticalPosition:"bottom",
			inverseNextAndPrevRotation:"yes",
			addKeyboardSupport:"yes",
			slideShowAutoPlay:"no",
			startSpaceBetweenButtons:4,
			spaceBetweenButtons:10,
			startSpaceForScrollBarButtons:20,
			startSpaceForScrollBar:6,
			hideControllerDelay:100,
			controllerMaxWidth:900,
			controllerBackgroundOpacity:1,
			controllerOffsetY:0,
			scrollBarOffsetX:0,
			scrollBarHandlerToolTipOffsetY:6,
			zoomInAndOutToolTipOffsetY:6,
			buttonsToolTipOffsetY:6,
			link:"http://www.google.com",
			buttonToolTipFontColor:"#a4a1a1",
			//----navigator----//
			showNavigator:"no",
			navigatorPosition:"topright",
			navigatorWidth:120,
			navigatorOffsetX:6,
			navigatorOffsetY:6,
			navigatorHandlerColor:"#FF0000",
			navigatorBorderColor:"#AAAAAA",
			//----info window----//
			infoWindowBackgroundOpacity:.6,
			infoWindowBackgroundColor:"#FFFFFF",
			infoWindowScrollBarColor:"#a4a1a1",
			//----markers-----//
			showMarkersInfo:"no",
			markerToolTipOffsetY:2,
			toolTipWindowMaxWidth:500,
			//----context menu----//
			showScriptDeveloper:"no",
			contextMenuLabels:"Rotate, Move/Pan, Rotate left, Rotate right, Zoom in/Zoom out, Play/Pause, Info, Custom link, Full screen/Normal screen",
			contextMenuBackgroundColor:"#393939",
			contextMenuBorderColor:"#6c6c6c",
			contextMenuSpacerColor:"#6c6c6c",
			contextMenuItemNormalColor:"#a4a1a1",
			contextMenuItemSelectedColor:"#FFFFFF",
			contextMenuItemDisabledColor:"#545252"
		});
  }

  render() {
    if (this.state.typeImages === null) {
      return (
        <FullPanel>
          <h1>Loading...</h1>
        </FullPanel>
      );
    } else {
      let slides = [];
      for (let slide of this.state.typeImages) {
        slides.push(<ul key={slide.url}><li data-small-image-path={slide.url}></li></ul>);
      }
      console.log(slides);
      return (
        <div className="TypeViewer ProductImage">
          <button onClick={this.props.onClose}>Close</button>
        	{/* div used as a holder for the grid */}
        	<div id="myDiv" style={{width:'550px', height:'550px', margin:'auto'}}></div>

        	{/* start viewer, display:none is added in case that js is disabled! */}
        	<div id="viewerPlaylist" style={{display:'none'}}>

        		{/* playlist (adding images) */}
        		<div data-paylist="">
              <div>
              {slides}
        			</div>
        		</div>
        	</div>
        </div>
      );
    }
  }
}
