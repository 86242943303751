import React from 'react';

import './NarrowPanel.css';

const NarrowPanel = (props) => {
  return (
    <div className="NarrowPanel">{props.children}</div>
  );
}

export default NarrowPanel;
