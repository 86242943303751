import Updater from './Updater';

export default class OEMUpdater extends Updater {
  dependent = false;
  name = 'OEMs';
  collection = 'oems';
  store = 'oems';

  update() {
    return this.api.fetchOEMs()
      .then(data =>
        this.clearStore().then(_ => this.storeData(data))
      );
  }
}
