import React from 'react';
import './FullPanel.css';

function FullPanel(props) {
  return (
    <div className="FullPanel">
      {props.children}
    </div>
  );
}

export default FullPanel;
