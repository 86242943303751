import Updater from './Updater';

export default class ProductUpdater extends Updater {
  dependent = true;
  name = 'Products';
  collection = 'products';
  store = 'products';

  update() {
    return this.repos.oems.all()
      .then(oems => this.updateWithOEMs(oems))
  }

  updateWithOEMs(oems) {
    // Download and populate products object store.
    return this.api.fetchProducts()
      .then(data =>
        this.clearStore().then(_ => this.updateWith(oems, data))
      );
  }

  updateWith(oems, data) {
    return this.db.then(db => {
      let done = 0;
      let count = data.products.length || 1;
      let lastPercent = 0;
      let funcs = [];

      // Map OEM names to OEM ids for quick lookup.
      let oemNames = [];
      for (let oem of oems) {
        oemNames[oem.id] = oem.name;
      }

      // Break our huge product data set into chunks so we can report on
      // progress. If we do it all in one go then there will be a large
      // pause without feedback where the transaction is building up. Instead
      // we run a number of smaller transactions in sequence.
      this.eachSlice(data.products, 750, products => {
        // Add each transaction task to funcs array.
        funcs.push(_ => {
          const tx = db.transaction(['products'], 'readwrite');
          const store = tx.objectStore('products');

          products.forEach(object => {
            // Add denormalized OEM data.
            object.oem = { name: oemNames[object.oem_id] };

            store.put(object).then(_ => {
              done ++;
              let percent = Math.round(100 * done / count);
              if (percent !== lastPercent) {
                lastPercent = percent;
                this.setState({ percentageComplete: percent });
              }
            })
          });
          return tx.complete;
        });
      });

      // Now run the transaction tasks.
      return this.promiseSerial(funcs);
    });
  }
}
