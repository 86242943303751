import React from 'react';

import './ParFit.css';
import parfit from './parfit.svg';

const ParFit = () => {
  return (
    <img className="ParFit" src={parfit} alt="Par Fit" />
  );
}

export default ParFit;
