class DatasheetRepositoryLocal {
  constructor(db, userDataPath) {
    this.db = db;
    this.userDataPath = userDataPath;
    this.datasheets = null;
  }

  matching(substring) {
    if (substring.length < 3) {
      return Promise.resolve([]);
    }

    return this.fetchDatasheets().then(function(datasheets) {
      let upCased = substring.toUpperCase();
      return datasheets
        .filter(d => d.partNumber.toUpperCase().indexOf(upCased) !== -1)
        .slice(0, 5);
    });
  }

  // Adds a datasheetUrl property to each object in parts whose parker_number
  // property matches datasheet partNumber.
  addUrls(parts) {
    return this.fetchDatasheets().then(function(datasheets) {
      // TODO: DRY (repeated in DatasheetRepository).
      // TODO: Key datasheets by partNumber for faster lookup than O(N2).
      return parts.map(function(p) {
        let datasheet = datasheets.filter(d => d.partNumber.toUpperCase() === p.parker_number)[0];
        if (datasheet) {
          let newPart = Object.assign({}, p);
          newPart.datasheetUrl = datasheet.url;
          return newPart;
        } else {
          return p;
        }
      });
    });
  }

  fetchDatasheets() {
    if (this.datasheets) {
      return Promise.resolve(this.datasheets);
    } else {
      return this.db.then(db => {
        const range = IDBKeyRange.bound('datasheets/', 'datasheets/\uffff', false, false);
        return db.transaction(['resources'], 'readonly')
          .objectStore('resources')
          .index('path')
          .getAll(range);
      }).then(resources => {
        this.datasheets = resources.map(r => ({
          modified: r.modified,
          path: r.path,
          partNumber: r.path.toUpperCase().replace('DATASHEETS/', '').replace('.PDF', ''),
          url: 'file://' + this.userDataPath + '/' + r.path
        }));
        return this.datasheets;
      });
    }
  }
}

export default DatasheetRepositoryLocal;
