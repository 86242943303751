import React, { Component } from 'react';
import './LabeledInputField.css';
import './LabeledSelectField.css';

class LabeledSelectField extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    var value = event.target.value;
    this.props.onChange(this.props.id, value);
  }

  render() {
    return (
      <div className="LabeledSelectField">
        <label className="LabeledInputField-label" htmlFor={this.props.id}>{this.props.label}</label>
        <select id={this.props.id} className="LabeledSelectField-field" onChange={this.handleChange} value={this.props.value}>
          <option></option>
          {this.props.children}
        </select>
      </div>
    );
  }
}

export default LabeledSelectField;
