import React, { Component } from 'react';
import InputField from './InputField';
import './LabeledInputField.css';

class LabeledInputField extends Component {
  render() {
    return (
      <div className="LabeledInputField">
        <label className="LabeledInputField-label" htmlFor={this.props.id}>{this.props.label}</label>
        <InputField id={this.props.id} type={this.props.type} onChange={this.props.onChange} value={this.props.value}/>
      </div>
    );
  }
}

export default LabeledInputField;
