import Jed from 'jed';

export default class Translation {
  constructor(data = {}) {
    this.i18n = new Jed(data);
  }

  translate(id) {
    let val = this.i18n.translate(id).fetch();
    if (val === id) {
      return this.prettifyUntranslated(id);
    }
    return val;
  }

  prettifyUntranslated(id) {
    let parts = id.split('.');
    let last = parts[parts.length - 1];
    return last.charAt(0).toUpperCase() + last.substr(1).replace(/_/g, ' ');
  }
}
