import React, { Component } from 'react';

import EmptyPanel from '../components/panels/EmptyPanel';
import FormGroup from '../components/FormGroup';
import Intro from '../components/Intro';
import PrimaryPanel from '../components/panels/PrimaryPanel';
import RadioButton from '../components/RadioButton';
import { TranslationContext } from '../translations/TranslationContext';

import background from '../information/information-bg.jpg';

class Language extends Component {
  constructor(props, context) {
    super(props, context);

    console.log('Language received props.locale', props.locale);
  }

  setLocale(locale) {
    this.props.onSetLocale(locale);
  }

  t = (id) => this.context.translate('language.language.' + id);

  render() {
    return (
      <div className="Language">
        <PrimaryPanel>
          <h2>{this.t('language')}</h2>
          <Intro>
            {this.t('intro')}
          </Intro>
          <FormGroup>
            <RadioButton
              label={this.t('english')}
              selected={this.props.locale === 'en_GB'}
              onSelect={() => this.setLocale('en_GB')}
            />
            <RadioButton
              label="Deutsche"
              selected={this.props.locale === 'de_DE'}
              onSelect={() => this.setLocale('de_DE')}
            />
            <RadioButton
              label="Français"
              selected={this.props.locale === 'fr_FR'}
              onSelect={() => this.setLocale('fr_FR')}
            />
          </FormGroup>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }
}

Language.contextType = TranslationContext;

export default Language;
