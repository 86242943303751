import { tr, detr } from './Muddy';

export default class ElementExpertRepositoryLocal {
  constructor(db) {
    this.db = db;
  }

  filtrationTypesAndStyles() {
    const fetches = [this.fetchFiltrationTypes(), this.fetchStyles()];

    return Promise.all(fetches).then(results => {
      let [filtrationTypes, styles] = results;
      let types = [];
      for (let ft of filtrationTypes) {
        types.push({
          name: ft.name,
          styles: styles.filter(s => s.filtration_type_id === parseInt(ft.id))
        });
      }
      return types;
    });
  }

  fetchStyle(id) {
    return this.db.then(db =>
      db.transaction('styles').objectStore('styles').get(id)
    );
  }

  fetchFiltrationTypes() {
    return this.db.then(db =>
      db.transaction('filtrationTypes').objectStore('filtrationTypes').getAll()
    );
  }

  fetchStyles() {
    return this.db.then(db =>
      db.transaction('styles').objectStore('styles').getAll()
    );
  }

  search(styleId, dimensions, tolerances) {
    return this.db.then(db => {
      let elements = [];
      let tx = db.transaction('elements');

      let criteria = [];
      for (let kv of Object.entries(dimensions)) {
        let key = kv[0];
        let val = parseInt(kv[1]);
        let tol = parseInt(tolerances[key]);
        if (!isNaN(val)) {
          criteria.push([
            'dimension_' + key.toLowerCase(), val - tol, val + tol
          ]);
        }
      }

      tx.objectStore('elements').index('style_id').openCursor(styleId).then(function cursorIterate(cursor) {
        if (!cursor) {
          return;
        }
        let element = cursor.value;
        let criteriaMet = true;
        for (let criterion of criteria) {
          let [dim, min, max] = criterion;
          let val = parseInt(element[dim]);
          if (isNaN(val) || val < min || val > max) {
            criteriaMet = false;
            break;
          }
        }
        if (criteriaMet) {
          elements.push(element);
        }
        return cursor.continue().then(cursorIterate);
      });
      return tx.complete.then(() => Promise.resolve(this.deob(elements)));
    });
  }

  deob(elements) {
    let arr = [];
    for (let element of elements) {
      if (!element) {
        continue;
      }
      let newElement = Object.assign({}, element);
      newElement.parker_number = detr(newElement.parker_number);
      arr.push(newElement);
    }
    return arr;
  }

  fetchElement(parkerNumber) {
    parkerNumber = tr(parkerNumber);
    return this.db.then(db =>
      db.transaction(['elements'], 'readonly')
        .objectStore('elements')
        .index('parker_number')
        .get(parkerNumber)
    ).then(element => {
      if (element) {
        return this.deob([element])[0];
      } else {
        return null;
      }
    });
  }
}
