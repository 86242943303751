export default class CannotFindRequest {
  /**
   * Constructs a request object with path and data properties that can be
   * passed to `API.post()`.
   */
  constructor(data) {
    this.path = 'cannot_find_enquiries';
    this.data = {
      file_data: data.fileData,
      file_name: data.fileName,
      manufacturer: data.manufacturer,
      part_number: data.partNumber,
      filtration_type: data.filtrationType,
      filter_dimensions: data.filterDimensions,
      notes: data.notes,
      quantity: data.quantity,
      demand_type: data.demandType,
      contact_opt_in: data.contactAccepted
    };
  }
}
