import React, { Component } from 'react';

import './ProductStatus.css';
import cross from './cross.svg';
import tick from './tick.svg';
import { TranslationContext } from '../translations/TranslationContext';

export default class ProductStatus extends Component {
  t = (id) => this.context.translate('product_info.product_status.' + id);

  render() {
    return (
      <div className="ProductStatus">
        <strong>{this.t('active')} {this.tickCross(this.props.element.active)}</strong>
        &nbsp; &nbsp;
        <strong>{this.t('stocked')} {this.tickCross(this.props.element.stocked)}</strong>
        <div className="ProductStatus-hr"></div>
        {this.tip()}
      </div>
    );
  }

  tickCross(boolean) {
    if (boolean) {
      return <img src={tick} alt="Yes" className="ProductStatus-tick-cross"/>;
    } else {
      return <img src={cross} alt="No" className="ProductStatus-tick-cross"/>;
    }
  }

  tip() {
    if (this.props.element.active) {
      if (this.props.element.stocked) {
        return null;
      } else {
        return <p>{this.t('min_order')}</p>;
      }
    } else {
      return <p>{this.t('min_order_consult')}</p>;
    }
  }
}

ProductStatus.contextType = TranslationContext;
