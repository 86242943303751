import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BigHeader from '../components/BigHeader';
import DefaultButton from '../components/DefaultButton';
import EmptyPanel from '../components/panels/EmptyPanel';
import Intro from '../components/Intro';
import PrimaryPanel from '../components/panels/PrimaryPanel';

import { TranslationContext } from '../translations/TranslationContext';

const background = require('./bg-register.jpg');

class SignInOrReset extends Component {
  render() {
    return (
      <div className="SignInOrReset">
        <PrimaryPanel>
          <BigHeader/>
          <Intro>
            <p>
              {this.t('intro')}
            </p>
          </Intro>
          <Link to="/">
            <DefaultButton text={this.t('sign_in')} />
          </Link>
          <Link to="/account/reset-password">
            <DefaultButton text={this.t('reset_password')} />
          </Link>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  t = (id) => this.context.translate('account.sign_in_or_reset.' + id);
}

SignInOrReset.contextType = TranslationContext;

export default SignInOrReset;
