import React, { Component } from 'react';

import ConditionalButton from '../components/ConditionalButton';
import LabeledSelectField from '../components/LabeledSelectField';

import { TranslationContext } from '../translations/TranslationContext';

class OEMAndModel extends Component {
  constructor(props) {
    super(props);

    this.oemRepository = props.repos.oems;
    this.productRepository = props.repos.products;

    this.state = {
      oem: '',
      oems: [],
      model: '',
      models: [],
    }

    this.handleOEMChange = this.handleOEMChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
  };

  t = (id) => this.context.translate('oem_apps.oem_and_model.' + id);

  canSearch() {
    return this.state.model;
  }

  componentDidMount() {
    let component = this;
    this.oemRepository.all().then(function(oems) {
      component.setState({ oems: oems });
    }).catch(function(error) {
      alert('Could not fetch list of OEMS');
    });
  }

  handleOEMChange(id, value) {
    let component = this;
    let oem = this.state.oems.find(
      oem => oem.id.toString() === value
    );
    if (typeof(oem) === 'undefined') {
      oem = '';
    }
    this.setState({ oem: oem, model: '' });
    this.productRepository.modelsForOEM(oem.id).then(function(models) {
      component.setState({ models: models });
    }).catch(function(error) {
      console.error(error);
    });
  }

  handleModelChange(id, value) {
    this.setState({ model: value });
  }

  handleSearch() {
    this.props.onSearch(this.state.oem.id, this.state.model);
  }

  render() {
    return (
      <div className="OEMAndModel">
        <h2>{this.t('oem_and_model')}</h2>
        <form onSubmit={(e) => { e.preventDefault(); this.handleSearch(); }}>
          <LabeledSelectField
            id="manufacturer"
            label={this.t('manufacturer')}
            value={this.state.oem.id}
            onChange={this.handleOEMChange}
          >{this.oemOptions()}</LabeledSelectField>
          <LabeledSelectField
            id="model"
            label={this.t('model')}
            value={this.state.model}
            onChange={this.handleModelChange}
          >{this.modelOptions()}</LabeledSelectField>
          <ConditionalButton
            type="submit"
            text={this.t('search')}
            loading={this.state.fetchingProducts}
            condition={() => this.canSearch()}
          />
        </form>
      </div>
    );
  }

  oemOptions() {
    return this.state.oems.map(
      oem => <option key={oem.id} value={oem.id}>{oem.name.substring(0,32)}</option>
    );
  }

  modelOptions() {
    if (this.state.oem) {
      return this.state.models.map(
        model => <option key={model} value={model}>{model}</option>
      );
    } else {
      return <option>{this.t('select_manufacturer')}</option>;
    }
  }
}

OEMAndModel.contextType = TranslationContext;

export default OEMAndModel;
