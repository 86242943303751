import React, { Component } from 'react';
import './LoadingButton.css';

class LoadingButton extends Component {
  render() {
    return (
      <button className="DefaultButton LoadingButton" disabled>
        {this.props.text}
      </button>
    );
  }
}

export default LoadingButton;
